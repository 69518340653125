import axios from 'axios';
import * as API from './api';

export const getAllVideosService = async (params) => {
  try {
    const res = await axios.get(API.videos, {
      headers: await API.Token({ isToken: true }),
      params,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const saveVideosService = async (id, data) => {
  let res;

  console.log('data video', data);

  if (id) {
    res = await axios.patch(`${API.videos}/${id}`, data, {
      headers: await API.Token({ isToken: true }),
    });
  } else {
    res = await axios.post(API.videos, data, {
      headers: await API.Token({ isToken: true }),
    });
  }
  return res;
};

export const deleteVideosService = async (id) => {
  try {
    const res = await axios.delete(`${API.videos}/${id}`, {
      headers: await API.Token({ isToken: true }),
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
