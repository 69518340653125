import { GET_SUSCRIPCIONES, SET_SUSCRIPCION_ACTIVO } from "../types";

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  loading: true,
};

export const suscripcionesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUSCRIPCIONES:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
        loading: false,
      };
    case SET_SUSCRIPCION_ACTIVO:
      return {
        ...state,
        activo: action.payload,
      };
    default:
      return state;
  }
};
