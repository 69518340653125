import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../../utils/constants';
import { RED_COLOR } from '..';

const LineRedServicios = () => {
  const mobile = useMediaQuery({ query: device.mobile });

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: mobile ? '3vw 0' : null,
      }}
    >
      <div
        style={{
          width: mobile ? '3vw' : '.7vw',
          height: mobile ? '3vw' : '.7vw',
          backgroundColor: RED_COLOR,
        }}
      ></div>

      <div
        style={{
          width: mobile ? '60vw' : '10vw',
          height: '.1vw',
          backgroundColor: RED_COLOR,
        }}
      ></div>
      <div
        style={{
          width: mobile ? '3vw' : '.7vw',
          height: mobile ? '3vw' : '.7vw',
          backgroundColor: RED_COLOR,
        }}
      ></div>
    </div>
  );
};

export default LineRedServicios;
