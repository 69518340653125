import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { RED_COLOR } from '.';
import { device } from '../../../utils/constants';

const HeaderServicios = () => {
  const mobile = useMediaQuery({ query: device.mobile });

  const landingStore = useSelector((state) => state.landing);

  const textEs = (
    <>
      <Label>
        FILMAR EN VENEZUELA ES{' '}
        <label style={{ color: RED_COLOR }}>MÁGICO</label>.
      </Label>
      <Label>
        HAZLO CON GENTE{' '}
        <label style={{ color: RED_COLOR }}>PROFESIONAL Y CERCANA,</label>{' '}
        <br />
        HAZLO CON <label style={{ color: RED_COLOR }}>PA‘ LOS PANAS.</label>
      </Label>
      <Label>
        NUESTRO TRABAJO ES HACER EL{' '}
        <label style={{ color: RED_COLOR }}>TUYO FÁCIL</label> <br />
        <label style={{ color: RED_COLOR }}>Y MEMORABLE.</label> SOMOS{' '}
        <label style={{ color: RED_COLOR }}>TU CREW IDEAL</label> PARA <br />
        FILMAR EN VENEZUELA.
      </Label>
    </>
  );

  const textEn = (
    <>
      <Label>
        SHOOTING IN VENEZUELA IS{' '}
        <label style={{ color: RED_COLOR }}>MAGICAL</label>.
      </Label>
      <Label>
        DO IT WITH <label style={{ color: RED_COLOR }}>PROFESSIONALS,</label>{' '}
        WITH <label style={{ color: RED_COLOR }}>PEOPLE YOU</label>
        <br />
        <label style={{ color: RED_COLOR }}>CAN TRUST</label>. SHOOT WITH{' '}
        <label style={{ color: RED_COLOR }}>PA‘ LOS PANAS.</label>
      </Label>
      <Label>
        OUR JOB IS TO MAKE YOURS{' '}
        <label style={{ color: RED_COLOR }}>EASY AND</label> <br />
        <label style={{ color: RED_COLOR }}>MEMORABLE</label>. WE ARE{' '}
        <label style={{ color: RED_COLOR }}>YOUR CREW IN</label>
        <br />
        <label style={{ color: RED_COLOR }}>VENEZUELA</label>
      </Label>
    </>
  );

  return (
    <Body>
      <Box>
        <Section
          style={{
            flexDirection: 'column',
          }}
        >
          <Titulo>PA' LOS PANAS PRODUCCIONES</Titulo>
          <Banner>PRODUCTION SERVICE VENEZUELA</Banner>
        </Section>
        <Section
          style={{
            paddingLeft: mobile ? '0' : '10vw',
          }}
        >
          <img
            src="../../img/diciembre2024/logoblack.png"
            style={{
              width: mobile ? '40vw' : '20vw',
              marginTop: mobile ? '0' : '3vw',
            }}
            alt="imagelogoblack"
          />
        </Section>
      </Box>
      <div
        style={{
          display: 'flex',
          width: '100vw',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '10vw',
          paddingLeft: mobile ? '2vw' : '0',
          paddingRight: mobile ? '2vw' : '0',
        }}
      >
        {landingStore.lang === 'es' ? textEs : textEn}
      </div>
    </Body>
  );
};

export default HeaderServicios;

const Body = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-image: url('../../img/service/header.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
`;

const Box = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10vw 0;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 0em 0em;
    margin: 5vw 0;
  }
`;

const Section = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 2em 0em;
    align-items: center;
  }
`;

const Titulo = styled.label`
  font-size: 5vw;
  font-family: FuturaBold;
  color: black;
  line-height: 4vw;
  padding-left: 8vw;
  padding-top: 4vw;

  @media ${device.mobile} {
    font-size: 10vw;
    line-height: 10vw;
    text-align: center;
    padding: 14vw 0 0 0;
  }
`;

const Banner = styled.div`
  background-color: black;
  font-family: FuturaBold;
  width: 50vw;
  color: white;
  display: flex;
  align-items: center;
  padding: 1.5vw 0 1.5vw 8vw;
  font-size: 3vw;
  line-height: 3vw;

  @media ${device.mobile} {
    font-size: 5vw;
    width: 100vw;
    padding: 7vw 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0vw;
    margin: 5vw 0;
  }
`;

const Label = styled.label`
  font-size: 3vw;
  font-family: FuturaBold;
  color: black;
  line-height: 3vw;
  text-align: center;
  margin-bottom: 2vw;

  @media ${device.mobile} {
    font-size: 5vw;
    line-height: 5vw;
    margin-bottom: 5vw;
  }
`;
