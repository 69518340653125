import * as React from 'react';
import { slideInUp } from 'react-animations';
import { BsPlayCircle } from 'react-icons/bs';
import styled, { css, keyframes } from 'styled-components';
import { device } from '../../../utils/constants';

const bounceAnimation = keyframes`${slideInUp}`;

const Container = styled.div`
  width: 50%;
  height: 22vw;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${(props) =>
    props.right &&
    css`
      align-items: flex-end;
    `};

  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  text-decoration: none;

  @media ${device.mobile} {
    width: 100%;
    height: 20em;
  }

  a {
    text-decoration: none;
  }

  svg {
    cursor: pointer;
  }
`;

export const BodyInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: center;
  justify-content: center;
  position: absolute;

  animation: 0.5s ${bounceAnimation};
`;

const CardVideosComponent = ({ img, onClick }) => {
  const [isHover, setIsHover] = React.useState(false);

  const info = (
    <BodyInfo>
      <BsPlayCircle
        onClick={onClick}
        style={{
          color: 'black',
          width: '4em',
          height: '4em',
        }}
      />
    </BodyInfo>
  );

  return (
    <Container
      img={img}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {isHover ? info : <></>}
    </Container>
  );
};

export default CardVideosComponent;
