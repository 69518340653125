import axios from "axios";
import * as API from "./api";

export const getAllClientesService = async () => {
  try {
    const res = await axios.get(API.clientes, {
      headers: await API.Token({ isToken: true }),
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkMensajeClientesService = async (id) => {
  try {
    const res = await axios.get(API.clientes + "/check/" + id, {
      headers: await API.Token({ isToken: true }),
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sendMensajeWebClientesService = async (data) => {
  try {
    const res = await axios.post(API.clientes, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllPageClientesService = async (page, perPage) => {
  try {
    const res = await axios.get(
      `${API.clientes}?page=${page}&limit=${perPage}`,
      {
        headers: await API.Token({ isToken: true }),
      },
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
