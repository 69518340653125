import React from "react";
import styled from "styled-components";
import { device } from "../../../utils/constants";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const NosotrosServicios = () => {
  const mobile = useMediaQuery({ query: device.mobile });
  const { t } = useTranslation();

  return (
    <Body>
      <Titulo>{t("service.nosotros.about")}</Titulo>
      <label
        style={{
          fontFamily: "FuturaLigth",
          color: "white",
          padding: mobile ? "2vw 2vw" : "2vw 8vw",
          textAlign: "center",
          fontSize: mobile ? "4vw" : "1.5vw",
        }}
      >
        {t("service.nosotros.text1")} <br />
        {t("service.nosotros.text2")} <br />
        {t("service.nosotros.text3")}
        <br />
        {t("service.nosotros.text4")}
      </label>
      <SubTitle> {t("service.nosotros.footer")}</SubTitle>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "20vw",
              height: "20vw",
              backgroundColor: "yellow",
              marginRight: "1.5vw",
            }}
          >
            <img
              src="../../img/service/8.jpg"
              alt={"imagesn1"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              width: "60vw",
              height: "20vw",
              backgroundColor: "red",
            }}
          >
            <img
              src="../../img/service/7.jpg"
              alt={"imagesn2"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "2vw",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "45%",
              height: "20vw",
              backgroundColor: "yellow",
              marginRight: "1.5vw",
            }}
          >
            <img
              src="../../img/service/danubio.jpg"
              alt={"imagesn3"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              width: "65%",
              height: "20vw",
              backgroundColor: "green",
            }}
          >
            <img
              src="../../img/service/9.jpg"
              alt={"imagesn4"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>
    </Body>
  );
};

export default NosotrosServicios;

const Body = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding: 3vw 10vw;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 10vw 0;
  }
`;

const Titulo = styled.label`
  font-size: 4vw;
  font-family: FuturaBold;
  color: white;

  @media ${device.mobile} {
    font-size: 10vw;
  }
`;

const SubTitle = styled.label`
  font-size: 2.5vw;
  font-family: FuturaBold;
  color: white;
  margin: 4vw 0;

  @media ${device.mobile} {
    font-size: 5vw;
    text-align: center;
    margin-bottom: 10vw;
  }
`;
