import React from 'react';
import styled from 'styled-components';
import Listado from './components/lista';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Clientes = () => (
  <Container>
    <h2>Clientes</h2>
    <Listado />
  </Container>
);
