import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import styled from "styled-components";
import {
  setActivoNewsletterAction,
  setActivoTiposBoletinAction,
} from "../../../store/actions";
import {
  IS_FORM_TIPOS_BOLETIN,
  IS_LIST_TIPOS_BOLETIN,
  SET_NEWSLETTER_ACTIVO,
  SET_TIPOS_BOLETIN_ACTIVO,
} from "../../../store/types";
import FormNewsletter from "./components/FormNewsletter";
import FormTiposBoletin from "./components/FormTiposBoletin";
import ListadoNewletter from "./components/ListaNewsletter";
import ListadoTiposBoletin from "./components/ListaTiposBoletin";

const Container = styled.div`
  width: 85vw;
  display: flex;
  flex-direction: column;
`;

export const Newsletter = () => {
  const dispatch = useDispatch();

  const tiposBoletinState = useSelector((state) => state.tiposBoletin);
  const newsletterState = useSelector((state) => state.newsletter);

  const [isModal, setIsModal] = useState();
  const toggle = async () => {
    setIsModal(!isModal);
    if (!isModal) await setActivoNewsletterAction(dispatch, null);
  };

  useEffect(() => {
    if (newsletterState.activo) setIsModal(true);
  }, [newsletterState.activo]);

  return (
    <Container>
      <h2>Newsletter</h2>

      <Row>
        <Col md={4}>
          {!tiposBoletinState.isList ? (
            <Button
              color="danger"
              style={{ marginRight: "10px" }}
              onClick={() => {
                dispatch({
                  type: SET_TIPOS_BOLETIN_ACTIVO,
                  payload: null,
                });
                setActivoNewsletterAction(dispatch, null);
                dispatch({
                  type: IS_LIST_TIPOS_BOLETIN,
                  payload: true,
                });
              }}
            >
              Volver
            </Button>
          ) : null}
          {tiposBoletinState.activo && !tiposBoletinState.isForm ? (
            <Button
              color="success"
              onClick={() => {
                dispatch({
                  type: SET_NEWSLETTER_ACTIVO,
                  payload: null,
                });
                setIsModal(true);
              }}
            >
              Nuevo GIF/JPG
            </Button>
          ) : (
            <Button
              color={tiposBoletinState.isForm ? "danger" : "success"}
              onClick={() => {
                setActivoTiposBoletinAction(dispatch, null);
                dispatch({
                  type: SET_NEWSLETTER_ACTIVO,
                  payload: null,
                });
                dispatch({
                  type: IS_FORM_TIPOS_BOLETIN,
                  payload: !tiposBoletinState.isForm,
                });
              }}
            >
              {tiposBoletinState.isForm
                ? "Cerrar formulario"
                : "Nuevo newsletter"}
            </Button>
          )}
        </Col>
      </Row>

      {tiposBoletinState.isForm ? (
        <Row>
          <Col md={12}>
            <FormTiposBoletin />
          </Col>
        </Row>
      ) : null}

      {!tiposBoletinState.isList ? (
        <ListadoNewletter />
      ) : (
        <ListadoTiposBoletin />
      )}
      <Modal
        isOpen={isModal}
        toggle={toggle}
        centered
        style={{ maxWidth: "80%" }}
      >
        <ModalHeader toggle={toggle}>
          {newsletterState.activo ? "Editar" : "Nuevo"} Newsletter
        </ModalHeader>
        <ModalBody>
          <FormNewsletter isModal={setIsModal} />
        </ModalBody>
      </Modal>
    </Container>
  );
};
