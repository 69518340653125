import React from "react";
import styled from "styled-components";
import { device } from "../../../../utils/constants";
import { useMediaQuery } from "react-responsive";

const TitleCardServicios = ({ title, text, images }) => {
  const mobile = useMediaQuery({ query: device.mobile });

  return (
    <>
      <div style={{ padding: mobile ? "5vw" : "2vw 8vw" }}>
        <Titulo>{title}</Titulo>
        <label
          style={{
            fontFamily: "FuturaMedium",
            fontSize: mobile ? "4vw" : null,
          }}
        >
          {text}
        </label>
      </div>
      <div
        style={{
          display: "flex",
          width: "100vw",
          justifyContent: "space-between",
          alignItems: "center",
          padding: mobile ? null : "0 8vw",
          flexDirection: mobile ? "column" : "row",
        }}
      >
        {images.map((el) => (
          <Imagen src={el.src} />
        ))}
      </div>
    </>
  );
};

export default TitleCardServicios;

const Imagen = styled.div`
  width: 27vw;
  height: 15vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  position: relative;

  background-image: url("${({ src }) => src}");
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.mobile} {
    width: 90%;
    height: 50vw;
    flex-direction: column;
    padding: 0;
    margin-bottom: 5vw;
  }
`;

export const Titulo = styled.label`
  font-size: 4vw;
  font-family: FuturaBold;
  color: black;

  @media ${device.mobile} {
    font-size: 8vw;
  }
`;
