import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { archivosFiles } from '../../../services/api';
import { getAllPublicoNewsletterAction } from '../../../store/actions';
import { CLEAR_ALL_PUBLICO_NEWSLETTER } from '../../../store/types';
import { device } from '../../../utils/constants';

const ContenidoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 3em 3em 3em;

  @media ${device.mobile} {
    margin: 0;
    padding: 0.5em 0;
  }
`;

const ListaNewsletterComponent = ({ select }) => {
  const dispatch = useDispatch();

  const newsletterStore = useSelector((state) => state.newsletter);

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  useEffect(() => {
    async function fetchData() {
      dispatch({
        type: CLEAR_ALL_PUBLICO_NEWSLETTER,
        payload: [],
      });
      if (select) {
        await getAllPublicoNewsletterAction(dispatch, {
          page: 1,
          tipoBoletin: select?.id,
        });
      }
    }
    fetchData();
  }, [select]);

  return newsletterStore.allPublico.map((el, i) => {
    return (
      <ContenidoDiv>
        <a
          href={el.link === '#' || !el.link ? null : el.link}
          target="_blank"
          rel="noreferrer"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: isMobileDevice
                ? `${el.anchoMovil ?? '90'}vw`
                : `${el.ancho ?? '80'}vw`,
              position: 'relative',
            }}
          >
            {el.png && (
              <img
                src={`${archivosFiles}/${el.png.folder}/${el.png.nombre}`}
                style={{
                  width: isMobileDevice
                    ? `${el.anchoMovilImg2 ?? '90'}vw`
                    : `${el.anchoImg2 ?? '80'}vw`,
                  position: 'absolute',
                }}
                alt={`gif_${i}`}
              />
            )}
            <img
              src={`${archivosFiles}/${el.gif.folder}/${el.gif.nombre}`}
              style={{ width: '100%', height: '100%' }}
              alt={`png_${i}`}
            />
          </div>
        </a>
      </ContenidoDiv>
    );
  });
};

export default ListaNewsletterComponent;
