import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { RED_COLOR } from '.';
import { device } from '../../../utils/constants';
import { openInNewTab } from '../landing-page/navbar/menu_mobile';

const DirectorServicios = () => {
  const mobile = useMediaQuery({ query: device.mobile });
  const { t } = useTranslation();

  return (
    <Body>
      <div
        style={{
          display: 'flex',
          width: mobile ? '90vw' : '80vw',
          height: mobile ? null : '20vw',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            width: mobile ? '30%' : '50%',
            height: mobile ? '30vw' : '13vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Redes
            onClick={() => openInNewTab('https://www.imdb.com/name/nm2866357/')}
          >
            IMDB
          </Redes>
          <Redes
            onClick={() =>
              openInNewTab('https://www.youtube.com/@palospanasprod')
            }
          >
            YOUTUBE
          </Redes>
          <Redes
            onClick={() =>
              openInNewTab(
                'https://www.linkedin.com/in/ignacio-castillo-cottin-a21703255/'
              )
            }
          >
            LINKEDIN
          </Redes>
        </div>
        <div
          style={{
            width: mobile ? '70%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'right',
            justifyContent: 'right',
          }}
        >
          <Titulo>EL INCA</Titulo>
          <Titulo>LA DANUBIO</Titulo>
          <Titulo>LA VIRGEN NEGRA</Titulo>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: mobile ? '100vw' : '80vw',
          flexDirection: mobile ? 'column' : 'row',
        }}
      >
        <div
          style={{
            width: mobile ? '100vw' : '58%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: mobile ? 'center' : 'right',
            justifyContent: mobile ? 'center' : 'right',
            marginTop: mobile ? '5vw' : null,
          }}
        >
          <SubTitle>IGNACIO CASTILLO COTTIN</SubTitle>
          <label
            style={{
              fontFamily: 'FuturaBold',
              color: 'white',
              fontSize: mobile ? '4vw' : '1.6vw',
            }}
          >
            {t('service.director.fundador')}
          </label>
          <label
            style={{
              fontFamily: 'FuturaLigth',
              color: 'white',
              fontSize: mobile ? '4vw' : '1.6vw',
            }}
          >
            {t('service.director.dir')}
          </label>
        </div>
        <div
          style={{
            width: mobile ? '100vw' : '42%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'right',
            justifyContent: 'right',
            marginTop: mobile ? '10vw' : '2vw',
          }}
        >
          <label
            style={{
              color: 'white',
              fontSize: mobile ? '4vw' : '1vw',
              textAlign: mobile ? 'center' : 'right',
              fontFamily: 'FuturaLigth',
              padding: mobile ? '5vw' : null,
            }}
          >
            {t('service.director.text1')}{' '}
            <label
              style={{
                color: '#fcee21',
                padding: '0vw 0.2vw',
                cursor: 'pointer',
                textDecoration: 'underline',
                textDecorationColor: 'transparent',
                transition: 'text-decoration-color 0.3s ease',
              }}
              onMouseEnter={(e) => {
                e.target.style.textDecorationColor = '#fcee21';
              }}
              onMouseLeave={(e) => {
                e.target.style.textDecorationColor = 'transparent';
              }}
              onClick={() =>
                openInNewTab(
                  'https://www.imdb.com/title/tt1149610/?ref_=nm_knf_t_1'
                )
              }
            >
              LA VIRGEN NEGRA (2008)
            </label>{' '}
            {t('service.director.text2')}{' '}
            <label
              style={{
                color: '#fcee21',
                padding: '0vw 0.2vw',
                cursor: 'pointer',
                textDecoration: 'underline',
                textDecorationColor: 'transparent',
                transition: 'text-decoration-color 0.3s ease',
              }}
              onMouseEnter={(e) => {
                e.target.style.textDecorationColor = '#fcee21';
              }}
              onMouseLeave={(e) => {
                e.target.style.textDecorationColor = 'transparent';
              }}
              onClick={() =>
                openInNewTab(
                  'https://www.imdb.com/title/tt4581310/?ref_=nm_knf_t_2'
                )
              }
            >
              EL INCA (2016),
            </label>{' '}
            {t('service.director.text3')}{' '}
            <label
              style={{
                color: '#fcee21',
                padding: '0vw 0.2vw',
                cursor: 'pointer',
                textDecoration: 'underline',
                textDecorationColor: 'transparent',
                transition: 'text-decoration-color 0.3s ease',
              }}
              onMouseEnter={(e) => {
                e.target.style.textDecorationColor = '#fcee21';
              }}
              onMouseLeave={(e) => {
                e.target.style.textDecorationColor = 'transparent';
              }}
              onClick={() =>
                openInNewTab(
                  'https://www.imdb.com/title/tt13444724/?ref_=nm_knf_c_3'
                )
              }
            >
              LA DANUBIO (2020),
            </label>{' '}
            {t('service.director.text4')}{' '}
          </label>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: mobile ? 'center' : 'flex-end',
              alignItems: 'flex-start',
            }}
          >
            <label
              style={{
                display: 'flex',
                color: RED_COLOR,
                fontSize: mobile ? '10vw' : '6vw',
                paddingRight: mobile ? '3vw' : '1vw',
                height: mobile ? '5vw' : '2vw',
                marginTop: mobile ? '3vw' : null,
              }}
            >
              *
            </label>
            <label
              style={{
                fontFamily: 'Futura',
                color: 'white',
                textAlign: 'right',
                marginTop: '1vw',
                fontSize: mobile ? '3.5vw' : '1.2vw',
                paddingRight: mobile ? '5vw' : null,
              }}
            >
              <br />
              {t('service.director.text5')}
              <br />
              {t('service.director.text6')}
            </label>
          </div>
        </div>
      </div>
    </Body>
  );
};

export default DirectorServicios;

const Body = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 5vw 10vw;
  background-image: url('../../img/service/director.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${device.mobile} {
    width: 100vw;
    flex-direction: column;
    padding: 10vw 0;
  }
`;

const Titulo = styled.label`
  width: 100%;
  font-size: 4vw;
  font-family: FuturaBold;
  color: #bc2c24;
  text-align: right;
  line-height: 4vw;

  @media ${device.mobile} {
    font-size: 6vw;
    line-height: 8vw;
  }
`;

const Redes = styled.label`
  font-size: 1.2vw;
  font-family: FuturaBold;
  color: white;
  margin-top: 1.8vw;
  line-height: 0.1vw;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;

  &:hover {
    text-decoration-color: #fcee21;
    color: #fcee21;
    cursor: pointer;
  }

  @media ${device.mobile} {
    font-size: 3vw;
    line-height: 3vw;
  }
`;

const SubTitle = styled.label`
  font-size: 3vw;
  font-family: FuturaBold;
  color: white;
  margin-top: 1.5vw;
  color: #bc2c24;

  @media ${device.mobile} {
    font-size: 6.5vw;
    text-align: center;
  }
`;
