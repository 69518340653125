import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { device } from "../../../../../utils/constants";

const ButtonLaCaja = styled.div`
  font-size: 1.4vw;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  position: fixed;
  z-index: 980;
  right: 2vw;
  bottom: 2vw;
  padding: 5px;

  img {
    width: 4vw;
  }

  @media ${device.mobile} {
    img {
      width: 12vw;
    }
  }
`;

const BotonLaCajaComponent = ({ onClick, src }) => {
  const mobile = useMediaQuery({ query: device.mobile });

  return (
    <ButtonLaCaja onClick={onClick}>
      <img
        src={"img/LOGO_PLP.gif"}
        style={{ width: mobile ? "17vw" : "5vw" }}
        alt="lacaja"
      />
    </ButtonLaCaja>
  );
};

export default BotonLaCajaComponent;
