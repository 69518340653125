import {
  GET_ALL_TIPOS_BOLETIN,
  SET_TIPOS_BOLETIN_ACTIVO,
  GET_ALL_PUBLICO_TIPOS_BOLETIN,
  IS_FORM_TIPOS_BOLETIN,
  IS_LIST_TIPOS_BOLETIN,
} from "../types";

const initialState = {
  all: [],
  allPublico: [],
  activo: null,
  isForm: false,
  isList: true,
};

export const tiposBoletinReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TIPOS_BOLETIN:
      return {
        ...state,
        all: action.payload.data,
      };
    case GET_ALL_PUBLICO_TIPOS_BOLETIN:
      return {
        ...state,
        allPublico: action.payload,
      };
    case IS_FORM_TIPOS_BOLETIN:
      return {
        ...state,
        isForm: action.payload,
      };
    case IS_LIST_TIPOS_BOLETIN:
      return {
        ...state,
        isList: action.payload,
      };
    case SET_TIPOS_BOLETIN_ACTIVO:
      return {
        ...state,
        activo: action.payload,
      };
    default:
      return state;
  }
};
