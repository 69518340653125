import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  flex-direction: row;
`;

const Register = () => {
  return (
    <>
      <Container>
        <h1>Registrooo</h1>
      </Container>
    </>
  );
};

export default Register;
