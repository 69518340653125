import * as React from 'react';
import { fadeIn } from 'react-animations';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { Modal, ModalBody } from 'reactstrap';
import styled, { keyframes } from 'styled-components';
import { device } from '../../../utils/constants';

const bounceAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
  width: 50vw;
  height: 26vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 100%;

  @media ${device.mobile} {
    width: 100vw;
    height: 56vw;
  }
`;

export const BodyInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
  position: absolute;

  animation: 0.5s ${bounceAnimation};
`;
export const Info = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-weight: normal;
    font-size: 1.8em;
    text-align: center;
    font-family: MontserratBold;
  }
`;

export const Links = styled.h2`
  cursor: pointer;
  text-align: center;
  font-family: MontserratMedium;
`;

const PublicidadComponent = ({ img, title, desc, url }) => {
  const [isHover, setIsHover] = React.useState(false);
  const [isModal, setIsModal] = React.useState(false);

  const toggle = () => {
    if (url === '') return;
    if (url === undefined) return;
    if (url === null) return;
    if (url === '-') return;

    setIsModal(!isModal);
  };

  const info = <BodyInfo></BodyInfo>;

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  return (
    <Container
      onClick={toggle}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      img={img}
    >
      {isHover && info}

      <Modal isOpen={isModal} toggle={toggle} centered={true} size="lg">
        <ModalBody style={{ backgroundColor: 'black' }}>
          <ReactPlayer
            width={isMobileDevice ? '100%' : null}
            url={url}
            playing
            controls
          />
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default PublicidadComponent;
