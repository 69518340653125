import axios from "axios";
import * as API from "./api";

export const loginService = async (user, password) => {
  const res = await axios.post(
    API.login,
    JSON.stringify({
      user,
      password,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return res;
};
