import { GET_CLIENTES, SET_CLIENTE_ACTIVO } from "../types";

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  loading: true,
};

export const clientesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTES:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
        loading: false,
      };
    case SET_CLIENTE_ACTIVO:
      return {
        ...state,
        activo: action.payload,
      };
    default:
      return state;
  }
};
