import styled from 'styled-components';
import { device } from '../../../utils/constants';

export const SectionFotos = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2vw;
  flex-wrap: wrap;

  @media ${device.mobile} {
    h1,
    h4 {
      text-align: center;
    }
  }
`;

export const CardFotos = styled.div`
  width: 25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  margin-bottom: 1.5vw;
  margin-right: 1.5vw;
  position: relative;

  @media ${device.mobile} {
    width: 90vw;
    height: 50vh;
  }
`;

export const CardImgaFotos = styled.div`
  width: 100%;
  height: 27vw;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${device.mobile} {
    height: 50vh;
  }
`;

export const CardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
`;

export const CardFooterFotos = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0px 10px 10px 10px;

  p {
    margin-bottom: 0px;
  }
`;

export const SectionTab = styled.div`
  width: 80%;
  height: 5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media ${device.mobile} {
  }
`;

export const ButtonTab = styled.div`
  font-size: 1.5em;
  cursor: pointer;
  padding: 0.2em 1em;
  border-bottom: ${({ activo }) =>
    activo ? '0.5vw solid #FCEE21' : '0px solid white'};
  font-weight: ${({ activo }) => (activo ? '700' : 'normal')};

  &:hover {
    font-size: 1.6em;
    background-color: #fcee21;
  }

  @media ${device.mobile} {
  }
`;

export const SectionArticulos = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2vw;
  flex-wrap: wrap;
`;

export const CardArticulos = styled.div`
  width: 25vw;
  height: 600px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #efefef;
  position: relative;
  margin-bottom: 1.5vw;
  margin-right: 1.5vw;

  @media ${device.mobile} {
    width: 90vw;
  }
`;

export const CardImgaArticulos = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: ${({ open }) => (!open ? 'default' : 'pointer')};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${device.mobile} {
  }
`;

export const BodyArticulos = styled.div`
  width: ${({ open }) => (open ? '72%' : '6%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1vw;
  position: absolute;
  background-color: ${({ color, open }) =>
    !open ? 'rgba(0, 0, 0, 0.5)' : color ?? '#c9c9c9'};
  box-shadow: 0 0 15px black;
  clip-path: inset(0px -15px 0px 0px);
  transition: width 1s ease-in-out;
  cursor: ${({ open }) => (open ? 'default' : 'pointer')};

  &:hover {
    background-color: ${({ color }) => color};
  }
`;

export const BodyArticulosTop = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 2vw;
  color: white;

  h4 {
    font-family: 'FuturaBold';
    color: white;
    font-size: 1.8em;
    text-align: start !important;
  }

  a {
    color: white;
    text-decoration: underline !important;
    font-size: 1em;
    cursor: pointer;

    &:hover {
      font-size: 1.1em;
      font-weight: 500;
    }
  }
`;

export const BodyArticulosBottom = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;

  p {
    font-size: 0.8em;
  }

  @media ${device.mobile} {
  }
`;

export const FechaArt = styled.div`
  margin-top: 1vw;
  font-size: 1em;
`;

export const SectionVideo = styled.div`
  width: 100vw;
  height: 60vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2vw;
  background-color: black;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 100;
`;

export const SectionBlog = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2vw;
  flex-wrap: wrap;

  @media ${device.mobile} {
    h1,
    h4 {
      text-align: center;
    }
  }
`;
