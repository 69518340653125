import { SET_DATA_AUTH, SET_LOGOUT } from "../types";

const initialState = {
  user: null,
  token: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_AUTH:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.accessToken,
      };

    case SET_LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
      };

    default:
      return state;
  }
};
