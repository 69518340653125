import React, { useState } from 'react';
import { slideInUp } from 'react-animations';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as Linka } from 'react-router-dom';
import { Link } from 'react-scroll';
import styled, { css, keyframes } from 'styled-components';
import * as CONST from '../../../../common/const';
import * as ROUTES from '../../../../router/routes';
import { SET_LANG, SET_SECTION_LANDING } from '../../../../store/types';

const slideInUpAimation = keyframes`${slideInUp}`;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'FuturaRegular';
  background-color: black;
  z-index: 990;

  a {
    text-decoration: none;
    color: white;
  }

  animation: 1s ${slideInUpAimation};
`;

const Menu = styled.div`
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-family: 'FuturaRegular';
  flex-direction: column;
  width: 100%;
  a {
    text-decoration: none;
  }

  padding-right: 5vw;
  margin-bottom: 20vw;

  ${(props) =>
    props.show &&
    css`
      animation: fadeIn 1s;
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `};
`;

const Button = styled.a`
  font-size: 9vw;
  font-family: 'FuturaMedium';
  color: black;
  cursor: pointer;
  width: 100%;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw 0;
  margin: 0px 10px;
  text-decoration: none !important;
  text-align: flex-start;

  &:hover {
    transition: 0.5s;
    font-size: 10vw;
    color: #fcee21;
    transition: 0.5s;
  }

  ${(props) =>
    props.isHover &&
    css`
      &:hover {
        background-color: #fcee21;
        color: black;
        font-size: 8vw;
      }
    `};

  ${(props) =>
    !props.siempre &&
    props.activo &&
    css`
      color: #fcee21;
      font-size: 8vw;
    `};

  ${(props) =>
    props.siempre &&
    props.activo &&
    css`
      background-color: #fcee21;
      color: black !important;
      font-size: 8vw;
      &:hover {
        color: black;
      }
    `};
`;

const BodyIconos = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 20vw;
`;

const Iconos = styled.img`
  width: ${({ w }) => w ?? '2.5em'};
  &:hover {
    transform: scale(1.2);
  }
`;

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const MenuMobile = ({ isLanding, onMenu, section, srcImg }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(
    window.localStorage.getItem('i18nextLngPLP') === 'en' ? 'en' : 'es'
  );
  const changeLang = (lang) => {
    setLang(lang);
    dispatch({
      type: SET_LANG,
      payload: lang,
    });
    i18n.changeLanguage(lang);
  };

  const homeRoute = isLanding ? (
    <Link
      to={CONST.HEADER}
      spy
      smooth
      duration={800}
      onClick={() => onMenu(CONST.HEADER)}
    >
      <Button activo={section === CONST.HEADER}>{t('menu.home')}</Button>
    </Link>
  ) : (
    <Linka to={ROUTES.LANDING_PAGE}>
      <Button
        onClick={() => {
          dispatch({
            type: SET_SECTION_LANDING,
            payload: CONST.HEADER,
          });
        }}
      >
        {' '}
        {t('menu.home')}
      </Button>
    </Linka>
  );

  const cajaRoute = isLanding ? (
    <Link
      to={CONST.LA_CAJA}
      spy
      smooth
      duration={800}
      onClick={() => onMenu(CONST.LA_CAJA)}
    >
      <Button activo={section === CONST.LA_CAJA} siempre isHover>
        {t('menu.caja')}
      </Button>
    </Link>
  ) : (
    <Linka to={ROUTES.LANDING_PAGE}>
      <Button
        onClick={() => {
          dispatch({
            type: SET_SECTION_LANDING,
            payload: CONST.LA_CAJA,
          });
        }}
      >
        {' '}
        {t('menu.caja')}
      </Button>
    </Linka>
  );

  return (
    <Container>
      <Menu>
        {homeRoute}

        <Linka to={ROUTES.NOSOTROS}>
          <Button activo={section === ROUTES.NOSOTROS}>
            {' '}
            {t('menu.nosotro')}
          </Button>
        </Linka>

        {cajaRoute}

        <Linka to={ROUTES.NEWSLETTER}>
          <Button activo={section === ROUTES.NEWSLETTER}>
            {' '}
            {t('menu.news')}
          </Button>
        </Linka>
        <Linka to={ROUTES.SERVICIOS}>
          <Button activo={section === ROUTES.SERVICIOS}>
            {' '}
            {t('menu.service')}
          </Button>
        </Linka>

        <a href="mailto:contacto@palospanas.com">
          <Button>{t('menu.aplica')}</Button>
        </a>

        <Button
          onClick={() => {
            window.location.reload();
            changeLang(lang === 'en' ? 'es' : 'en');
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {lang === 'en' ? 'EN' : 'ES'}
          </div>
        </Button>
      </Menu>
      <BodyIconos>
        <Iconos
          onClick={() =>
            openInNewTab(
              'https://www.youtube.com/channel/UC3ra91gf1g2RToJ7nwjRG8g'
            )
          }
          src={`${srcImg ?? 'img'}/youtube.png`}
        />
        <Iconos
          onClick={() =>
            openInNewTab(
              'https://www.linkedin.com/company/74562983/admin/feed/posts/'
            )
          }
          src={`${srcImg ?? 'img'}/linkend.png`}
        />
        <Iconos
          onClick={() =>
            openInNewTab(
              'https://instagram.com/palospanasprod?igshid=1t0x78sa705f0'
            )
          }
          src={`${srcImg ?? 'img'}/insta.png`}
        />
        <Iconos
          onClick={() => openInNewTab('https://twitter.com/palospanasprod')}
          src={`${srcImg ?? 'img'}/twitter.png`}
        />
        <Iconos
          w="2.2em"
          onClick={() => openInNewTab('https://www.tiktok.com/@palospanasprod')}
          src={`${srcImg ?? 'img'}/tiktok.png`}
        />

        <Iconos
          onClick={() => openInNewTab('https://vimeo.com/palospanas')}
          src={`${srcImg ?? 'img'}/vimeo.png`}
        />
        <Iconos
          w="3.5em"
          onClick={() =>
            openInNewTab(
              'https://www.imdb.com/search/title/?companies=co0531373'
            )
          }
          src={`${srcImg ?? 'img'}/imdb.png`}
        />
      </BodyIconos>
    </Container>
  );
};

export default MenuMobile;
