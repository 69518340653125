import { GET_SUSCRIPCIONES, SET_SUSCRIPCION_ACTIVO } from "../types";
import * as API from "../../services";

export const getAllSuscripcionesAction = async (dispatch, params) => {
  const res = await API.getAllSuscripcionesService(params);
  dispatch({
    type: GET_SUSCRIPCIONES,
    payload: res.data,
  });
  return res;
};

export const checkMensajeSuscripcionesAction = async (dispatch, id) => {
  const res = await API.checkMensajeSuscripcionesService(id);
  dispatch({
    type: SET_SUSCRIPCION_ACTIVO,
    payload: res.data.data,
  });
  alert(res.data.message);
  return res.data.statusCode === 200;
};

export const setActivoSuscripcionesAction = async (dispatch, data) => {
  dispatch({
    type: SET_SUSCRIPCION_ACTIVO,
    payload: data,
  });
};
