import React from "react";
import styled from "styled-components";
import { device } from "../../../utils/constants";
import TitleCardServicios from "./components/title-card.servicios";
import { useTranslation } from "react-i18next";

const CastingServicios = () => {
  const { t } = useTranslation();

  return (
    <Body>
      <TitleCardServicios
        title={t("service.loc.casting")}
        text={t("service.loc.casText")}
        images={[
          {
            src: "../../img/service/chica.png",
          },
          {
            src: "../../img/service/maggi.png",
          },
          {
            src: "../../img/service/navidad.png",
          },
        ]}
      />
      <TitleCardServicios
        title={t("service.loc.crew")}
        text={t("service.loc.crewText")}
        images={[
          {
            src: "../../img/service/explica.jpg",
          },
          {
            src: "../../img/service/corte.jpg",
          },
          {
            src: "../../img/service/reunion.jpg",
          },
        ]}
      />
      <TitleCardServicios
        title={t("service.loc.equipos")}
        text={t("service.loc.equText")}
        images={[
          {
            src: "../../img/service/toma1.jpg",
          },
          {
            src: "../../img/service/6.jpg",
          },
          {
            src: "../../img/service/camara.jpeg",
          },
        ]}
      />
    </Body>
  );
};

export default CastingServicios;

const Body = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  margin-bottom: 4vw;
  margin-top: 1vw;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 0em 0em;
  }
`;
