import {
  SET_CAJA_FUERTE,
  SET_VIDEO_PROPAGANDA,
  SET_VIDEO_CINE,
  SET_IS_CODE_CONTACTO,
  SET_IS_LEYENDA,
  SET_IS_LEYENDA_IMG,
  SET_IS_SUSCRIPTION,
  SET_LANG,
} from "../types";

const initialState = {
  cajaFuerte: false,
  isVideoPropaganda: false,
  isVideoCine: false,
  isCodeContacto: false,
  isLeyenda: false,
  isLeyendaImg: false,
  isSuscription: true,
  lang: "es",
};

export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAJA_FUERTE:
      return {
        ...state,
        cajaFuerte: action.payload,
      };
    case SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case SET_IS_SUSCRIPTION:
      return {
        ...state,
        isSuscription: action.payload,
      };

    case SET_VIDEO_PROPAGANDA:
      return {
        ...state,
        isVideoPropaganda: action.payload,
      };

    case SET_VIDEO_CINE:
      return {
        ...state,
        isVideoCine: action.payload,
      };

    case SET_IS_CODE_CONTACTO:
      return {
        ...state,
        isCodeContacto: action.payload,
      };

    case SET_IS_LEYENDA:
      return {
        ...state,
        isLeyenda: action.payload,
      };

    case SET_IS_LEYENDA_IMG:
      return {
        ...state,
        isLeyendaImg: action.payload,
      };

    default:
      return state;
  }
};
