import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import styled from 'styled-components';
import { getAllPageNewsletterService } from '../../../../services';
import { archivosFiles } from '../../../../services/api';
import {
  deleteNewsletterAction,
  getAllNewsletterAction,
  setActivoNewsletterAction,
} from '../../../../store/actions';
import { GET_CLIENTES } from '../../../../store/types';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .botones {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;

const ListadoNewletter = () => {
  const dispatch = useDispatch();
  const tiposBoletinState = useSelector((state) => state.tiposBoletin);
  const newsletterState = useSelector((state) => state.newsletter);

  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [loadingData, setLoadingData] = useState();

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState();
  const toggle = () => {
    setIsModalDelete(false);
    setActivoNewsletterAction(dispatch, null);
  };

  async function getAllNewsletter() {
    setLoadingRefresh(true);
    await getAllNewsletterAction(dispatch, {
      tipoBoletin: tiposBoletinState.activo.id,
    });
    setLoadingRefresh(false);
  }

  useEffect(() => {
    getAllNewsletter();
  }, []);

  const columns = [
    {
      name: 'ID',
      width: '60px',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Imagen Gif',
      selector: (row) => row.gif,
      sortable: true,
      width: '130px',
      cell: (row) => (
        <div
          style={{
            width: '100px',
            height: '100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            paddingBottom: '7px',
          }}
        >
          <img
            alt="pintureImg"
            width="100px"
            height="100px"
            src={
              row.gif
                ? `${archivosFiles}/${row.gif.folder}/${row.gif.nombre}`
                : '../../img/picture.png'
            }
          />
        </div>
      ),
    },
    {
      name: 'Tipo',
      selector: (row) => row.tipoBoletin?.nombre,
      sortable: true,
    },
    {
      name: 'Posicion',
      width: '7vw',
      selector: (row) => row.posicion,
      sortable: true,
    },
    {
      name: 'Publico',
      sortable: true,
      width: '8vw',
      cell: (row) =>
        row.public ? (
          <h5>
            <Badge color="success">Activo</Badge>
          </h5>
        ) : (
          <h5>
            <Badge color="danger">Bloqueado</Badge>
          </h5>
        ),
    },
    {
      name: '',
      width: '120px',
      cell: (row) => (
        <div>
          <Button
            size="sm"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={async () => {
              await setActivoNewsletterAction(dispatch, row);
            }}
          >
            <FaEdit />
          </Button>
          <Button
            size="sm"
            color="danger"
            onClick={() => {
              setDataDelete(row);
              setIsModalDelete(true);
            }}
          >
            <FaTrashAlt />
          </Button>
        </div>
      ),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const getAllHandler = async (page) => {
    setLoadingData(true);
    const res = await getAllPageNewsletterService({
      page,
      limit: newsletterState.meta.itemsPerPage,
    });
    dispatch({
      type: GET_CLIENTES,
      payload: res,
    });
    setLoadingData(false);
  };

  const handlePageChange = (page) => {
    getAllHandler(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoadingData(true);
    const res = await getAllPageNewsletterService(page, newPerPage);
    dispatch({
      type: GET_CLIENTES,
      payload: res,
    });
    setLoadingData(false);
  };

  return (
    <Container>
      <Row>
        <Col>
          <h4>{tiposBoletinState.activo.nombre}</h4>
        </Col>
        <Col>
          <div className="botones">
            <Button
              disabed={loadingRefresh}
              onClick={() => {
                setLoadingRefresh(true);
                getAllNewsletter();
              }}
            >
              {loadingRefresh ? (
                <>
                  <Spinner size="sm" />
                  &nbsp;&nbsp;Actualizando lista...
                </>
              ) : (
                'Actualizar'
              )}
            </Button>
          </div>
        </Col>
      </Row>
      <DataTable
        columns={columns}
        data={newsletterState.all}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        dense
        striped
        pointerOnHover
        highlightOnHover
        onRowClicked={async (row, event) => {
          await setActivoNewsletterAction(dispatch, row);
        }}
        paginationPerPage={
          newsletterState.meta !== null && newsletterState.meta !== undefined
            ? newsletterState.meta.itemsPerPage
            : 50
        }
        paginationRowsPerPageOptions={[10, 30, 50]}
        paginationTotalRows={
          newsletterState.meta !== null && newsletterState.meta !== undefined
            ? newsletterState.meta.totalItems
            : 0
        }
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationServer
        progressPending={loadingData}
      />
      <Modal isOpen={isModalDelete} toggle={toggle}>
        <ModalHeader toggle={toggle}>Eliminar</ModalHeader>
        <ModalBody>
          ¿Seguro que deseas eliminar este registro?
          <h3>{dataDelete ? dataDelete.titulo : ''}</h3>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={async () => {
              await deleteNewsletterAction(dispatch, dataDelete.id, {
                tipoBoletin: tiposBoletinState.activo.id,
              });
              toggle();
            }}
          >
            Eliminar
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default ListadoNewletter;
