import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MIS_VIDEOS } from '../../../common/const';
import { GOBIERNO, MUD, PLAN, SPOTS } from '../../../router/routes';
import { SET_CAJA_FUERTE, SET_VIDEO_PROPAGANDA } from '../../../store/types';
import LaCaja from '../landing-page/lacaja/lacaja';
import LayoutNosotros from '../nosotros/layoutNosotros';
import SectionVideoComponent from './SectionVideo';
import CardPropagandaComponent from './cardPropaganda';
import { SectionForm, SectionMisVideos } from './components';

const PagePropaganda = ({ isVideosNew = false }) => {
  const dispatch = useDispatch();
  const layoutState = useSelector((state) => state.layout);
  let history = useHistory();

  const { t } = useTranslation();

  const handleDispatch = () => {
    if (layoutState.cajaFuerte) {
      let h = document.getElementById('sectionTitulo').clientHeight;
      window.scrollTo(0, h);
      dispatch({
        type: SET_CAJA_FUERTE,
        payload: false,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    const x = localStorage.getItem(MIS_VIDEOS);
    dispatch({
      type: SET_CAJA_FUERTE,
      payload: false,
    });
    dispatch({
      type: SET_VIDEO_PROPAGANDA,
      payload: x === 'true',
    });
    handleDispatch();
  }, []);

  const body = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {layoutState.isVideoPropaganda ? (
        <>
          <SectionMisVideos>
            <CardPropagandaComponent
              img={'img/prop_Plan_pais.png'}
              title="PLAN PAÍS"
              onClick={() => history.push(PLAN)}
            />
            <CardPropagandaComponent
              img={'img/prop_Gob_interino.png'}
              title="GOBIERNO INTERINO"
              onClick={() => history.push(GOBIERNO)}
            />
            <CardPropagandaComponent
              img={'img/prop_Mud.png'}
              title="MUD 2021"
              onClick={() => history.push(MUD)}
            />
          </SectionMisVideos>
          {layoutState.isVideoPropaganda ? (
            <CardPropagandaComponent
              img={'img/imgSpots.png'}
              title="SPOTS"
              full
              onClick={() => history.push(SPOTS)}
            />
          ) : (
            <SectionVideoComponent />
          )}
        </>
      ) : (
        <>
          <SectionVideoComponent />
          <SectionForm>
            <LaCaja lacaja codes={true} isRoute={false} isPropaganda />
          </SectionForm>
        </>
      )}
    </div>
  );

  if (isVideosNew) {
    return body;
  }

  return (
    <LayoutNosotros
      title={t('publicidad.propaganda')}
      subTitle={t('publicidad.proText')}
      isSmooth
    >
      {body}
    </LayoutNosotros>
  );
};

export default PagePropaganda;
