import axios from "axios";
import * as API from "./api";

export const getAllSuscripcionesService = async (params) => {
  try {
    const res = await axios.get(API.suscripciones, {
      headers: await API.Token({}),
      params,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkMensajeSuscripcionesService = async (id) => {
  try {
    const res = await axios.get(API.suscripciones + "/check/" + id, {
      headers: await API.Token({}),
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sendMensajeWebSuscripcionesService = async (data) => {
  try {
    const res = await axios.post(API.suscripciones, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
