import { GET_CLIENTES, SET_CLIENTE_ACTIVO } from "../types";
import * as API from "../../services";

export const getAllClientesAction = async ({ dispatch }) => {
  const res = await API.getAllClientesService();
  dispatch({
    type: GET_CLIENTES,
    payload: res.data,
  });
  return res;
};

export const checkMensajeClientesAction = async ({ dispatch, id }) => {
  const res = await API.checkMensajeClientesService(id);
  dispatch({
    type: SET_CLIENTE_ACTIVO,
    payload: res.data.data,
  });
  alert(res.data.message);
  return res.data.statusCode === 200;
};

export const setActivoClientesAction = async ({ dispatch, data }) => {
  dispatch({
    type: SET_CLIENTE_ACTIVO,
    payload: data,
  });
};
