import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutNosotros from '../nosotros/layoutNosotros';
import CardVideosComponent from './CardVideos';
import DetallesPropaganda from './DetallesPropaganda';

export const videosMud = [
  {
    img: '../img/propaganda/mud/video-mud-1.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Aqui-Estamos-1-1min.html?player=public01&at=eyJpdiI6InF5WU5TckFvR1N5ZGNOZ2VlRkhCMmc9PSIsInZhbHVlIjoicWtObmJFSlRLT3FSU0hxcnI5UlU5SnBwekFPYXAyMmN1VXpKR0o0TmFtMD0iLCJtYWMiOiJjZjExNjM2OTEwZDZiY2RlY2E3Yzg0OTRhNDc4NWVhY2FhNWU0Y2I1ZjE3MjA1OTkzNWJhMjA5YTcxYzkxMTM3IiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-2.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Aqu-estamos-12.html?player=public01&at=eyJpdiI6ImR0ZGpaVkVYVklzWFNWSkp4MEFvSWc9PSIsInZhbHVlIjoiK2VJQ05uaExLcDR2cTZRMHllYSsxVU9XbjNsTlRtSXZCL2Y0dFkxV3pNVT0iLCJtYWMiOiI0MzE3YjZjMTExZjA4NmM5M2I3MDZjZWIzMTZkMzgxZDAxNjYxNGY2OWMxN2JkNGE2ZTFjNTE4NTIyY2U2MGE3IiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-3.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Aqu-Estamos-6-30s.html?player=public01&at=eyJpdiI6ImZpenFYTjBLakI4NlcybXBMVkxEMmc9PSIsInZhbHVlIjoiTXpGcm1uRTExT2NjaG1Jc0p2dnJ0ZHNIOHRUMThGYkNpaU1pNmgrZGd6az0iLCJtYWMiOiJlZDY5ODgyOGZkMmZkODM2NmMxYzI0YWMyODg0M2Y4Yzc3MWViZDE3OTc4NDM4Y2Q0NWY2Njg3MTQ5NjE4YmYzIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-4.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Promesas-incumplidas-4-Lacava.html?player=public01&at=eyJpdiI6IktveGFEdHhvQlZLK1R6aExFT0t5d0E9PSIsInZhbHVlIjoiSXpVdFc0UGN0YVl1cE9zZ25XZ1BJZ1hYZUdyeFJrTTZkWU1lb3Z4MzRCdz0iLCJtYWMiOiI1NGIzNGYwODA5OGUzZDczNTExOTRkYzBhYjI2NzdkNjc4Y2VjOTQ3MDMyZTM3ZDk3YjkxYWNkYzdjODRmODEwIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-5.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Cierre_Unidad_2_H264.html?player=public01&at=eyJpdiI6InE3Q0c2WW9DOG4xeVdLc01LYWp0amc9PSIsInZhbHVlIjoiZDI1WGxHaVUwbW1SWUtWNnhlNnhXdmRNODM5TVVSNFQ3US9QaDBwSkNUZz0iLCJtYWMiOiJjNGIzZTIwZDc5MmFkYTYzZmQwOWU2NDc0ODc2MjIxNzNiZDVmOWY3YzA0YjJjNDhkNGFhMGZkMTYzMDJhOGQ5IiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-6.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Yo-Voto-4.html?player=public01&at=eyJpdiI6IlA4anFacnlBWHZxUWE3eCsyNFRydlE9PSIsInZhbHVlIjoiaFFvNnJCcHZGeGZDaXRYWEdkeFhsUm5zNlA5KzBEVnlocC9qRjlPZlVkQT0iLCJtYWMiOiI3NWJjZGRiYjA0Mzk0NTQ1NDcxMzI3ODViMjg3NDNhODFhMTRiZDE5YmE3NzMyNWVjNTA5YTA2Nzk0MTBiZjA2IiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-7.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/No-votes-pues-8.html?player=public01&at=eyJpdiI6Imtmb1h4T3doNUxRWVdtd3g5NkNlR3c9PSIsInZhbHVlIjoiS2FCNEYxMGN2SVVQcXRDRzJ0YVVyVDJpeE03U3RzU0lLM2F1MTlxMnZRST0iLCJtYWMiOiIyZjNjOTkyNmNiNzdlNjg0MjQzMDJlMDkzMGRmNDUyZDYyNDljYmEyMTI2NDQ4YjMzNmI1ZDFhYjU2NzRlZDkxIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-8.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/No-votes-pues-5.html?player=public01&at=eyJpdiI6IkxJaXNPdDNHUnFpN1pWanArMjlzamc9PSIsInZhbHVlIjoiSGdGblNBb0J2azNobjJydWNxQnVXZ0J3aXFyV1pBL0RldGZiUk5zcS9OMD0iLCJtYWMiOiIwNTI5YzVhNTE1M2Y1YjVlOWZkNjJiZmVlODFkZTdkMDgxZDE3MzMyNmY1OGZkNDQ3NTJkZDkwMDhhM2RhOTBiIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-9.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Yo-Voto-x-Unidad-1.html?player=public01&at=eyJpdiI6IlI4VktJeHlTZW8rWHhUOWxUZjBQclE9PSIsInZhbHVlIjoiR21FVllHbkkzVVVaYklzZWxoOU5ZS3pvRFJ6dnVBb3J2cDFwOWRYQmVvcz0iLCJtYWMiOiI3Yjg0ZmY0MzIwMjliZGViODhmODk1MWY4YTlkOWJlMzNmZjI1YmNlMTZiNjI4YTk1MjI1MzdhNDExN2U3ZThhIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-10.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Yo-Voto-pq-Vamos-Unidos-2.html?player=public01&at=eyJpdiI6InZ2RDJDd3BKK3laaTIwcnNEazJiQXc9PSIsInZhbHVlIjoiVjIvbmVnUExtVGZRb1VIcTl0Z3lwNTR0TVhnemxOVEEvcDdLRmxyYXpYUT0iLCJtYWMiOiJlMzBlYTVkYTViYTU4NDZkNzVlOWQzZjExMGFhZTkzOWJlODBlZGNkZDVmYjQxMTIyOTkxNDBjZjhjMGU3ZjRiIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-11.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Yo-Voto-x-Unidad-8.html?player=public01&at=eyJpdiI6IjBic3JxY2lDUzdRK0k4U01RSmlpRGc9PSIsInZhbHVlIjoiVlNIWmZ6Z3NsL2trZmFPNmVZN3Jyd2QyeUlLaXRXalA0Z1dWSEs2SFVhQT0iLCJtYWMiOiIwOWZiNzFmZWVmN2JkZjQ1ZWIzMTY1OTUxYWVlZjNjYTM3NjFhMDRhOWQ2YTM1YmY4OTJhNzdmZWUxOGFmZmVmIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-12.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Yo-Voto-5.html?player=public01&at=eyJpdiI6IlZNOWhaUWpwN2JtNzlib0F1OHF6SUE9PSIsInZhbHVlIjoiLzcxOTRvYjRtRjdOdDVwazVxbFRCeWJSYWRBekxiRnVtTkFkUHVFOWkwQT0iLCJtYWMiOiJiNjA0ZjQ1ZWI4MzdlMTA3MDJmYmEwOTM4NTZhMDkwOGE2MTU4NDg0YWMzZTQzOGU4ZTIwYTc3N2Y1ZDFlNDZjIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-13.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Yo-Voto-x-Unidad-4.html?player=public01&at=eyJpdiI6IllJYmREVDRhbmUvN2t0d2pWcjYzSVE9PSIsInZhbHVlIjoiMFMwVHFZeGxta0sxRzZjWElPWkxwOWQwNk1SYWg1amxYYWNYU215QzlXaz0iLCJtYWMiOiI3MTdiOGY1OTBjMjJiOTc2YjA5MWRhODllYzY2NDE2ZDU5NjJhMDg1OTI1NzJiOWI2YmQ1YWEzM2NlZmQwMzA1IiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/mud/video-mud-14.JPG',
    url: 'https://media.publit.io/file/Propaganda/MUD2021/Yo-Voto-pq-Vamos-Unidos-5.html?player=public01&at=eyJpdiI6Imd6OHREKzhKSGpiK1owR1U2VDcxeEE9PSIsInZhbHVlIjoiSHZZWlhDNzczcWlEMlROZ05ZRElFNVNUWDJad0h5QkYrY2RRK3RoWVhuOD0iLCJtYWMiOiI4ZTgzMTQ0YmZkNzc5OGQ3YjM5M2I1NDIyY2M5NDEwZWI1ZDk0YTMzMWNiZjIyZDVhYTBlZDhmM2U5NmIyYTE5IiwidGFnIjoiIn0=',
  },
];

const MudPage = () => {
  const { t } = useTranslation();

  const graficas = [
    '../img/propaganda/mud/mud2.png',
    '../img/propaganda/mud/mud1.png',
    '../img/propaganda/mud/mud6.jpg',
    '../img/propaganda/mud/mud5.jpg',
  ];

  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState('');

  const toggle = () => setModal(!modal);

  return (
    <LayoutNosotros
      title={'MUD 2021'}
      subTitle={t('publicidad.propaganda')}
      isSmooth
      logo="../img/diciembre2024/logoblack.png"
      srcImg="../img"
    >
      <DetallesPropaganda
        infoE="Agrupar a los partidos bajo un mismo mensaje visualizando al venezolano de a pie como
el gran opositor frente al gobierno."
        infoR="Rescatar el voto como herramienta de lucha y generador de un posible cambio político."
        graficas={graficas}
        modal={modal}
        toggle={toggle}
        videoLink={videoLink}
      >
        {videosMud.map((el) => {
          return (
            <CardVideosComponent
              img={el.img}
              onClick={() => {
                setModal(true);
                setVideoLink(el.url);
              }}
            />
          );
        })}
      </DetallesPropaganda>
    </LayoutNosotros>
  );
};

export default MudPage;
