import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { SET_LANG } from '../../../../../store/types';
import { device } from '../../../../../utils/constants';

const ButtonLang = styled.div`
  color: white;
  font-size: 1.4vw;
  cursor: pointer;
  color: ${({ white }) => (white ? 'white' : 'black')};
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  position: fixed;
  z-index: 999;
  right: 3vw;
  top: 3.5vw;
  padding: 5px;
`;

const BotonLang = ({ isWhite }) => {
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const [lang, setLang] = useState(
    window.localStorage.getItem('i18nextLngPLP') === 'en' ? 'en' : 'es'
  );
  const changeLang = (lang) => {
    setLang(lang);
    dispatch({
      type: SET_LANG,
      payload: lang,
    });
    i18n.changeLanguage(lang);
  };

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  const [colorLang, setColorLang] = useState(true);
  const checkScrollTop = () => {
    setColorLang(window.pageYOffset > 300);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
  }, []);

  return isMobileDevice ? (
    <></>
  ) : (
    <ButtonLang
      alt="Cambio de idioma"
      white={isWhite ?? !colorLang}
      onClick={() => {
        window.location.reload();
        changeLang(lang === 'en' ? 'es' : 'en');
      }}
    >
      {lang === 'en' ? 'EN' : 'ES'} <FaAngleDown style={{ color: '#FCEE21' }} />{' '}
    </ButtonLang>
  );
};

export default BotonLang;
