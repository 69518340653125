import styled from 'styled-components';
import { device } from '../../../utils/constants';

export const SectionForm = styled.div`
  width: 35vw;
  height: 45vw;
  display: flex;
  align-items: center;
  flex-direction: column;

  .formlacaja {
    width: 50vw;
  }

  @media ${device.mobile} {
    width: 90vw;
  }
`;

export const BodyPlay = styled.div`
  width: 30vw;
  height: 22vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  margin: 0vw 3vw;

  .formlacaja {
    width: 50vw;
  }

  iframe {
    box-sizing: border-box;
    height: 300px;
  }

  @media ${device.mobile} {
    height: 30vh;

    iframe {
      box-sizing: border-box;
    }
  }
`;

export const SectionPublicidad = styled.div`
  width: 100vw;
  height: ${(props) => props.h};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4em;

  @media ${device.mobile} {
    margin-top: 2em;
  }
`;
