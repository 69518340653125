import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';
import Router from './router/Router';
import { persistor, store } from './store/store';
AOS.init();

const clearCookiesAndData = async () => {
  // const isReload = await localStorage.getItem('isReload');
  // if (isReload === '0' || isReload === null) {
  //   // Eliminar cookies
  //   document.cookie.split(';').forEach((cookie) => {
  //     const name = cookie.split('=')[0].trim();
  //     document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  //   });
  //   // Eliminar data del sitio web
  //   if (window.caches) {
  //     window.caches.keys().then((cacheNames) => {
  //       cacheNames.forEach((cacheName) => {
  //         window.caches.delete(cacheName);
  //       });
  //     });
  //   }
  //   // Eliminar localStorage y sessionStorage
  //   localStorage.clear();
  //   sessionStorage.clear();
  console.log('Cookies and data cleared');
  // localStorage.setItem('isReload', '1');
  //   window.location.reload();
  // }
};

const App = () => {
  useEffect(() => {
    clearCookiesAndData();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div>Loading</div>}>
          <Router />
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
