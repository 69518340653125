import {
  GET_ALL_CODIGOS,
  SET_CODIGOS_ACTIVO,
  SET_CODIGOS_ACTIVO_CINE,
} from "../types";

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  activoCine: null,
};

export const codigosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CODIGOS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case SET_CODIGOS_ACTIVO:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_CODIGOS_ACTIVO_CINE:
      return {
        ...state,
        activoCine: action.payload,
      };
    default:
      return state;
  }
};
