import React from 'react';
import styled from 'styled-components';
import { device } from '../../../utils/constants';
import { useMediaQuery } from 'react-responsive';
import LineRedServicios from './components/line-red.servicios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RED_COLOR } from '.';

const BannerServicios = ({ name, text, foto }) => {
  const mobile = useMediaQuery({ query: device.mobile });
  const layoutState = useSelector((state) => state.layout);

  const { t } = useTranslation();

  const tags = {
    fontSize: mobile ? '4vw' : null,
    fontFamily: 'FuturaLigth',
  };

  return (
    <Body>
      <div
        style={{
          width: layoutState.lang === 'en' ? '85%' : '95%',
        }}
      >
        <Label style={{ color: RED_COLOR }}>{t('service.somos')}</Label>
        <Label size="5vw">{t('service.casa')}</Label>
        <Label
          size="5vw"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {t('service.audiovisual')}{' '}
          <label style={{ color: RED_COLOR, padding: '0 1vw' }}>
            {t('service.and')}
          </label>{' '}
          <label style={{ paddingTop: '2vw' }}>{t('service.agencia')}</label>
        </Label>
        <Label size="5vw" align="end">
          {t('service.comunicacion')}
        </Label>
        <Label size="5vw" align="end">
          {t('service.integral')}
        </Label>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: mobile ? 'column' : 'row',
            justifyContent: 'space-around',
            width: '100%',
            marginTop: mobile ? '10vw' : '2vw',
            color: 'white',
          }}
        >
          <div style={tags}>{t('service.tags.idea')}</div>
          <LineRedServicios />
          <div style={tags}>{t('service.tags.desarrollo')}</div>
          <LineRedServicios />
          <div style={tags}>{t('service.tags.ejecucion')}</div>
          <LineRedServicios />
          <div style={tags}>{t('service.tags.difusion')}</div>
        </div>
      </div>
    </Body>
  );
};

export default BannerServicios;

const Body = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8vw 10vw;
  background-color: black;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 10vw 5vw;
  }
`;

const Label = styled.div`
  background-color: black;
  font-family: FuturaBold;
  color: white;
  font-size: ${({ size }) => size ?? '3.3vw'};
  line-height: 4.5vw;
  text-align: ${({ align }) => align ?? 'start'};

  @media ${device.mobile} {
    font-size: 5.2vw;
    line-height: 6vw;
  }
`;
