import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../../../../utils/constants';
import CardPerson from '../../nosotros/card-person';
import { MenuItem } from '../home/MenuHome';
import LayoutMenu from '../home/layoutHome';

const Title = styled.div`
  color: white;
  font-size: 1.3vw;
  font-family: 'MontserratLight';
  text-align: center;
  width: 60vw;
  margin-top: 3vw;

  @media ${device.mobile} {
    font-size: 0.9em;
    width: 95vw;
  }
`;

const Header = styled.div`
  margin-top: 5vw;

  @media ${device.mobile} {
    margin-top: 10vw;
    margin-bottom: 6vw;
  }
`;

const NosotrosNew = () => {
  const { t } = useTranslation();

  const mobile = useMediaQuery({ query: device.mobile });

  return (
    <LayoutMenu>
      <Header>
        <Fade delay={100} duration={1500}>
          <MenuItem color="white" isCursor={false} isHover={false}>
            {t('menu.nosotro')}
          </MenuItem>
        </Fade>
      </Header>
      <Fade delay={300} duration={1500}>
        <Title>
          {t('nosotros.text1')}
          <br />
          <div
            style={{
              margin: '0 4vw',
            }}
          >
            {t('nosotros.text2')}
          </div>
          <br />
          {t('nosotros.text3')}
        </Title>
      </Fade>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '10VW',
        }}
      >
        <Fade delay={500} duration={1500}>
          <div
            style={{
              display: 'flex',
              flexDirection: mobile ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'center',

              width: '100%',
              marginTop: '2VW',
            }}
          >
            <CardPerson
              name="Ignacio Castillo Cottin"
              text={t('nosotros.ignacio')}
              foto="IGNACIO.jpg"
            />
            <CardPerson
              name="Jessica Jalfón"
              text={t('nosotros.jessica')}
              foto="JESSICA.jpg"
            />
            <CardPerson
              name="Valentina Sperandio"
              text={t('nosotros.valentina')}
              foto="VALENTINA.jpg"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: mobile ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <CardPerson
              name="Verónica Gazzara"
              text={t('nosotros.veronica')}
              foto="VERONICA.jpg"
            />
            <CardPerson
              name="Sasha Ackerman"
              text={t('nosotros.sasha')}
              foto="SASHA.jpg"
            />
          </div>
        </Fade>
      </div>
    </LayoutMenu>
  );
};

export default NosotrosNew;
