import styled from 'styled-components';
import { device } from '../../../utils/constants';

export const SectionForm = styled.div`
  width: 35vw;
  height: 55vw;
  display: flex;
  align-items: center;
  flex-direction: column;

  .formlacaja {
    width: 50vw;
  }

  @media ${device.mobile} {
    width: 100vw;
    height: 100vh;
  }
`;

export const SectionMisVideos = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
