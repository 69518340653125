import React, { useState } from 'react';
import { slideInDown } from 'react-animations';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled, { keyframes } from 'styled-components';
import { LANGS, OPEN_MENU } from '../../../../store/types';
import { device } from '../../../../utils/constants';

export const i18nextLngPLPConst = 'i18nextLngPLP';

const slideInUpAimation = keyframes`${slideInDown}`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 999;
  top: 2vw;
  right: 2vw;

  animation: 1s ${slideInUpAimation};

  @media ${device.mobile} {
    top: 1em;
    right: 1em;
  }
`;

const ButtonIcon = styled.img`
  width: ${({ wd }) => wd ?? '3.4vw'};
  height: ${({ hp }) => hp ?? '1.9vw'};
  cursor: pointer;

  @media ${device.mobile} {
    width: ${({ wd }) => wd ?? '2.3em'};
    height: ${({ hp }) => hp ?? '2em'};
  }
`;

const ButtonLang = styled.div`
  font-size: 1.5vw;
  font-weight: w600;
  color: ${({ menuBlack }) => (!menuBlack ? 'white' : 'black')};
  cursor: pointer;
  font-family: 'MontserratLight';

  @media ${device.mobile} {
    font-size: 1em;
  }
`;

const Menu = ({ menuBlack = false }) => {
  const dispatch = useDispatch();
  const landingStore = useSelector((state) => state.landing);

  const mobile = useMediaQuery({ query: device.mobile });

  const [lang, setLang] = useState(
    window.localStorage.getItem(i18nextLngPLPConst)
  );

  const changeLang = (langs) => {
    window.localStorage.setItem(i18nextLngPLPConst, langs);
    setLang(langs);
    dispatch({
      type: LANGS,
      payload: langs,
    });
  };

  const hanlderOpenMenu = (value = undefined) => {
    dispatch({
      type: OPEN_MENU,
      payload: value,
    });
  };

  return (
    <Container>
      <ButtonIcon
        onClick={() => hanlderOpenMenu(!landingStore.openMenu)}
        menuBlack={menuBlack}
        wd={landingStore.openMenu ? (mobile ? '2.3em' : '3.1vw') : null}
        hp={landingStore.openMenu ? (mobile ? '1.5em' : '1.6vw') : null}
        src={`img/${
          landingStore.openMenu
            ? menuBlack
              ? 'closeBlack.png'
              : 'closeWhite.png'
            : menuBlack
              ? 'menuBlack.png'
              : 'menuWhite.png'
        }`}
      />
      <ButtonLang
        alt="Cambio de Idioma"
        white={true}
        menuBlack={menuBlack}
        onClick={() => {
          changeLang(lang === 'en' ? 'es' : 'en');
          window.location.reload();
        }}
      >
        {lang === 'en' ? 'ESP' : 'ENG'}
      </ButtonLang>
    </Container>
  );
};

export default Menu;
