import React from 'react';
import styled from 'styled-components';
import { device } from '../../../utils/constants';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const LocationServicios = ({ name, text, foto }) => {
  const mobile = useMediaQuery({ query: device.mobile });
  const { t } = useTranslation();

  const images = [
    {
      title: 'SALTO ÁNGEL',
      src: '../../img/service/1.jpg',
    },
    {
      title: 'LOS ROQUES',
      src: '../../img/service/2.jpg',
    },
    {
      title: 'MÉRIDA',
      src: '../../img/service/3.jpg',
    },
    {
      title: 'RORAIMA',
      src: '../../img/service/4.jpg',
    },
    {
      title: 'MÉDANOS DE CORO',
      src: '../../img/service/5.jpg',
    },
  ];
  return (
    <Body>
      <div style={{ padding: mobile ? '5vw' : '2vw 8vw' }}>
        <Titulo>{t('service.loc.locacion')}</Titulo>
        <label
          style={{
            fontFamily: 'FuturaMedium',
            fontSize: mobile ? '4vw' : null,
          }}
        >
          {t('service.loc.locText')}
        </label>
      </div>
      {images.map((el) => (
        <Location>
          <StyledImage src={el.src} mobile={mobile} />
          <Label>{el.title}</Label>
        </Location>
      ))}
    </Body>
  );
};

export default LocationServicios;

const Body = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }
`;

const StyledImage = styled.div`
  width: 100%;
  height: ${({ mobile }) => (mobile ? '50vw' : '31vw')};
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Titulo = styled.label`
  font-size: 4vw;
  font-family: FuturaBold;
  color: black;

  @media ${device.mobile} {
    font-size: 8vw;
  }
`;

const Location = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  position: relative;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }
`;

const Label = styled.div`
  color: white;
  position: absolute;
  bottom: 3vw;
  left: 8vw;
  font-size: 2vw;
  font-weight: bold;
  font-family: FuturaBold;

  @media ${device.mobile} {
    font-size: 4vw;
  }
`;
