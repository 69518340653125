import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import {
  saveTiposBoletinAction,
  setActivoTiposBoletinAction,
} from '../../../../store/actions';

const FormTiposBoletin = () => {
  const dispatch = useDispatch();
  const tiposBoletinState = useSelector((state) => state.tiposBoletin);

  const [nombre, setNombre] = useState('');

  const [isActivo, setIsActivo] = useState(true);

  const validarInputs = () => {
    const nombreValid = nombre === '';
    return !nombreValid;
  };

  const [loadingSave, setLoadingSave] = useState(false);

  const handlerSubmit = async () => {
    const valid = await validarInputs();
    if (valid) {
      let data = {
        nombre,
        isActivo,
      };
      setLoadingSave(true);

      await saveTiposBoletinAction(dispatch, tiposBoletinState.activo?.id, {
        ...data,
        isActivo: data.isActivo === 'true',
      });
      setLoadingSave(false);
      setActivoTiposBoletinAction(dispatch, null);
    }
  };

  useEffect(() => {
    if (tiposBoletinState.activo) {
      setNombre(tiposBoletinState.activo.nombre);
      setIsActivo(tiposBoletinState.activo.isActivo);
    }
  }, [tiposBoletinState.activo]);

  return (
    <Form>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="nombre">Nombre *</Label>
            <Input
              id="nombre"
              name="nombre"
              value={nombre}
              required
              onChange={(e) => setNombre(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="isActivo">Estatus</Label>
            <Input
              id="isActivo"
              name="select"
              type="select"
              value={isActivo}
              onChange={(e) => setIsActivo(e.target.value)}
            >
              <option value={true} selected>
                Activo
              </option>
              <option value={false}>Bloqueado</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={4}>
          <Label for="isActivo"></Label>
          <br />
          <Button
            color="success"
            onClick={handlerSubmit}
            style={{ marginTop: '8px' }}
          >
            {loadingSave ? (
              <>
                <Spinner size="sm" />
                &nbsp;&nbsp;Guardando registro...
              </>
            ) : (
              'GUARDAR'
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormTiposBoletin;
