import React from 'react';
import { slideInDown } from 'react-animations';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { Modal } from 'reactstrap';
import styled, { keyframes } from 'styled-components';
import { MIS_VIDEOS } from '../../../common/const';
import { PROPAGANDA } from '../../../router/routes';
import { SET_CODIGOS_ACTIVO, SET_VIDEO_PROPAGANDA } from '../../../store/types';
import { device } from '../../../utils/constants';
import BotonLaCajaComponent from '../landing-page/navbar/components/BotonLaCaja';

const bounceAnimation = keyframes`${slideInDown}`;

const BodyGrafica = styled.div`
  width: 100%;
  background-color: black;
  animation: 0.5s ${bounceAnimation};
  display: flex;
  justify-content: ${({ jc }) => jc ?? 'space-evenly'};

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;
const CardGrafica = styled.div`
  width: ${({ w }) => w ?? '24vw'};
  height: ${({ h }) => h ?? '24vw'};
  background-color: black;

  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  @media ${device.mobile} {
    width: ${({ w }) => w ?? '90vw'};
    height: ${({ h }) => h ?? '90vw'};
    margin-bottom: 1em;
  }
`;

const Estrategia = styled.div`
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: row;
  padding: 7vw;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const InfoTitle = styled.div`
  width: 50%;
  display: flex;
  color: white;
  flex-direction: column;

  @media ${device.mobile} {
    width: 100%;
  }

  label {
    font-size: 4vw;
    font-family: FuturaBold;
    font-weight: bold;

    @media ${device.mobile} {
      font-size: 12vw;
    }
  }

  texto {
    font-size: 1.1em;
    margin-top: 1.8vw;
    margin-left: 0.5vw;
    color: grey;

    @media ${device.mobile} {
      font-size: 1.1em;
    }
  }
`;

const BodyVideos = styled.div`
  width: 100%;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
`;

export const ContainerVideos = styled.div`
  width: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const DetallesPropaganda = ({
  infoE,
  infoR,
  graficas,
  children,
  modal,
  toggle,
  videoLink,
  categoria,
}) => {
  const dispatch = useDispatch();
  const layoutState = useSelector((state) => state.layout);
  let history = useHistory();

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  return (
    <>
      <BodyGrafica jc={categoria === 'gob' ? 'center' : null}>
        {categoria === 'gob' ? (
          <CardGrafica
            w={isMobileDevice ? '90vw' : '40vw'}
            h={isMobileDevice ? '40vh' : '25vw'}
            img={'../img/propaganda/gob/gob2.png'}
          />
        ) : (
          <>
            <CardGrafica img={graficas[0]} />
            <CardGrafica img={graficas[1]} />
            <CardGrafica img={graficas[2]} />
            <CardGrafica img={graficas[3]} />
          </>
        )}
      </BodyGrafica>
      <Estrategia>
        <InfoTitle
          style={{
            paddingRight: '2vw',
          }}
        >
          <label>RACIONAL</label>
          <texto>{infoR}</texto>
        </InfoTitle>
        <InfoTitle>
          <label>ESTRATEGIA</label>
          <texto>{infoE}</texto>
        </InfoTitle>
      </Estrategia>
      <BodyVideos>{children}</BodyVideos>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="lg"
        className="modal-blog-video"
      >
        <center>
          <figure
            style={{
              left: 0,
              width: '100%',
              height: 0,
              position: 'relative',
              paddingBottom: '56.25%',
              marginBlockEnd: 0,
              marginBlockStart: 0,
              marginInlineStart: 0,
              marginInlineEnd: 0,
            }}
          >
            <iframe
              title="video"
              id="pv_mMawGz38"
              src={videoLink}
              scrolling="no"
              style={{
                border: 0,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                position: 'absolute',
                overflow: 'hidden',
              }}
              allowFullScreen="false"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            />
          </figure>
        </center>
      </Modal>
      <BotonLaCajaComponent
        src={layoutState.isVideoPropaganda ? '../img/closeYellow.png' : null}
        onClick={() => {
          dispatch({
            type: SET_VIDEO_PROPAGANDA,
            payload: false,
          });
          localStorage.setItem(MIS_VIDEOS, false);
          dispatch({
            type: SET_CODIGOS_ACTIVO,
            payload: null,
          });
          history.push(PROPAGANDA);
        }}
      />
    </>
  );
};

export default DetallesPropaganda;
