import React from 'react';
import styled from 'styled-components';
import { colors, device } from '../../../../utils/constants';

const OuterDiv = styled.div`
  width: ${({ isCategory }) => (isCategory ? '1.2em' : '1.5em')};
  border-radius: 0.7em;
  height: ${({ isCategory }) => (isCategory ? '1.2em' : '1.5em')};
  background-color: ${colors.primary};
  border: ${({ isMobile }) =>
    isMobile ? '.08em solid white' : '0.1em solid white'};
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobile} {
    width: ${({ isCategory }) => (isCategory ? '.6em' : '.8em')};
    height: ${({ isCategory }) => (isCategory ? '.6em' : '.8em')};
  }
`;

const InnerDiv = styled.div`
  width: ${({ isCategory }) => (isCategory ? '.7em' : '.9em')};
  height: ${({ isCategory }) => (isCategory ? '.7em' : '.9em')};
  border-radius: ${({ isCategory }) => (isCategory ? '.35em' : '0.45em')};
  background-color: white;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 1s cubic-bezier(0.25, 0.8, 0.25, 1);

  @media ${device.mobile} {
    width: ${({ isCategory }) => (isCategory ? '.3em' : '.5em')};
    height: ${({ isCategory }) => (isCategory ? '.3em' : '.5em')};
  }
`;

const CheckendWidget = ({ active, onClick, isCategory = false, isMobile }) => (
  <OuterDiv onClick={onClick} isCategory={isCategory} isMobile={isMobile}>
    <InnerDiv active={active} isCategory={isCategory} isMobile={isMobile} />
  </OuterDiv>
);

export default CheckendWidget;
