import {
  GET_ALL_BLOG,
  SET_BLOG_ACTIVO,
  GET_ALL_PUBLICO_BLOG,
  CLEAR_ALL_PUBLICO_BLOG,
} from "../types";

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  allPublico: [],
  totalPagesArt: 1,
  currentPageArt: 1,
  totalPagesFoto: 1,
  currentPageFoto: 1,
};

export const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BLOG:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case GET_ALL_PUBLICO_BLOG:
      return {
        ...state,
        allPublico: action.payload.all,
        page: action.payload.page,
        totalPagesArt: action.payload.totalPagesArt,
        currentPageArt: action.payload.currentPageArt,
        totalPagesFoto: action.payload.totalPagesFoto,
        currentPageFoto: action.payload.currentPageFotoe,
      };
    case CLEAR_ALL_PUBLICO_BLOG:
      return {
        ...state,
        allPublico: action.payload,
        page: 1,
        totalPagesArt: 1,
        currentPageArt: 1,
        totalPagesFoto: 1,
        currentPageFoto: 1,
      };
    case SET_BLOG_ACTIVO:
      return {
        ...state,
        activo: action.payload,
      };
    default:
      return state;
  }
};
