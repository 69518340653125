import { useState } from 'react';
import { slideInDown } from 'react-animations';
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { colors, device } from '../../../../utils/constants';

const slideInUpAimation = keyframes`${slideInDown}`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: row;
  margin-top: 10vw;
  color: white;
  cursor: pointer;

  animation: 1s ${slideInUpAimation};
  transition: 0.5s color ease-in-out;

  &:hover {
    color: ${colors.hover};
  }

  @media ${device.mobile} {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5em;

    &:hover {
      color: ${colors.hover};
    }
  }
`;

export const MenuItem = styled.div`
  font-size: 1.1vw;
  font-family: 'MontserratBold';
  color: ${({ color }) => color};

  padding: 0 1vw;
  line-height: 1.2vw;
  cursor: ${(isCursor = true) => (isCursor ? 'pointer' : null)};
  letter-spacing: 0.2vw;

  @media ${device.mobile} {
    font-size: ${({ fs }) => fs ?? '0.8em'};
    padding: 0.2em 1em;
    margin: 2vw 0vw;
    height: 1em;

    &:hover {
      font-size: ${({ isHover }) => (isHover ? '1.5vw' : null)};
    }
  }

  a {
    text-decoration: none !important;
    color: ${({ color }) => color};
    cursor: ${(isCursor) => (isCursor ? 'pointer' : 'none')};
  }
`;

const Divider = styled.div`
  width: 0.13vw;
  height: 1.3vw;
  background-color: ${({ color }) => color};
  margin: 0 0.3vw;
  cursor: pointer;
  display: ${({ display }) => display};

  transition: 0.5s background-color ease-in-out;

  @media ${device.mobile} {
    width: 0.15em;
    margin: 0.3em 0;
    height: 1em;
  }
`;

const HomeMenu = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const mobile = useMediaQuery({ query: device.mobile });

  const [isHover, setIsHover] = useState(false);

  return (
    <Container
      onClick={() => {
        history.push('/servicios');
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <Fade delay={500} duration={2000}>
        <MenuItem
          onMouseEnter={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          {t('portafolio.concepto.title')}
        </MenuItem>

        <Divider color={isHover ? colors.hover : 'white'} />

        <MenuItem
          onMouseEnter={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          {t('portafolio.estrategia.title')}
        </MenuItem>
        <Divider color={isHover ? colors.hover : 'white'} />
        <MenuItem
          onMouseEnter={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          {t('portafolio.ejecucion.title')}
        </MenuItem>

        <Divider
          color={isHover ? colors.hover : 'white'}
          display={mobile ? 'none' : 'flex'}
        />
        {mobile ?? <br />}

        <MenuItem
          onMouseEnter={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          {t('portafolio.comunicaciones.title')}
        </MenuItem>
        <Divider color={isHover ? colors.hover : 'white'} />
        <MenuItem
          onMouseEnter={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          {t('portafolio.experiencias.title')}
        </MenuItem>
        <Divider color={isHover ? colors.hover : 'white'} />
        <MenuItem
          br=""
          onMouseEnter={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          {t('portafolio.talentos.title')}
        </MenuItem>
      </Fade>
    </Container>
  );
};

export default HomeMenu;
