import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useVideosHook = () => {
  const { t } = useTranslation();

  const [selectedsType, setSelectedsType] = useState([]);
  const [selectedsCategories, setSelectedsCategories] = useState([]);

  const tiposArray = [
    {
      id: 'cine',
      label: t('menu.cine'),
    },
    {
      id: 'publicidad',
      label: t('menu.publicidad'),
    },
    {
      id: 'propaganda',
      label: t('menu.propaganda'),
    },
  ];

  const categoriasArray = [
    {
      id: 'concepto',
      label: t('portafolio.concepto.title'),
    },
    {
      id: 'estrategia',
      label: t('portafolio.estrategia.title'),
    },
    {
      id: 'ejecucion',
      label: t('portafolio.ejecucion.title'),
    },
    {
      id: 'comunicacion',
      label: t('portafolio.comunicaciones.title'),
    },
    {
      id: 'experiencia',
      label: t('portafolio.experiencias.title'),
    },
    {
      id: 'talento',
      label: t('portafolio.talentos.title'),
    },
  ];

  return {
    selectedsType,
    selectedsCategories,
    setSelectedsCategories,
    setSelectedsType,

    tiposArray,
    categoriasArray,
  };
};

export default useVideosHook;
