import {
  GET_ALL_TIPOS_BOLETIN,
  SET_TIPOS_BOLETIN_ACTIVO,
  GET_ALL_PUBLICO_TIPOS_BOLETIN,
  IS_FORM_TIPOS_BOLETIN,
} from "../types";
import {
  deleteTiposBoletinService,
  getAllTiposBoletinService,
  getAllPublicoTiposBoletinService,
  saveTiposBoletinService,
} from "../../services";

export const getAllTiposBoletinAction = async (dispatch, params) => {
  try {
    const res = await getAllTiposBoletinService(params);
    dispatch({
      type: GET_ALL_TIPOS_BOLETIN,
      payload: res.data,
    });
    return res;
  } catch (error) {}
};

export const getAllPublicoTiposBoletinAction = async (dispatch, page) => {
  try {
    const res = await getAllPublicoTiposBoletinService({ page });
    dispatch({
      type: GET_ALL_PUBLICO_TIPOS_BOLETIN,
      payload: res.data.data,
    });

    return res.status === 200;
  } catch (e) {
    return false;
  }
};

export const saveTiposBoletinAction = async (dispatch, id, data) => {
  try {
    const res = await saveTiposBoletinService(id, data);
    dispatch({
      type: IS_FORM_TIPOS_BOLETIN,
      payload: false,
    });
    alert(res.message);
    await getAllTiposBoletinAction(dispatch, null);
    return res.statusCode < 300;
  } catch (error) {
    alert(error);
    console.log(error);
  }
};

export const setActivoTiposBoletinAction = async (dispatch, data) => {
  dispatch({
    type: SET_TIPOS_BOLETIN_ACTIVO,
    payload: data,
  });
};

export const deleteTiposBoletinAction = async (dispatch, id) => {
  const res = await deleteTiposBoletinService(id);
  await getAllTiposBoletinAction(dispatch, null);
  alert(res.message);
  return res;
};
