import React from 'react';
import { fadeIn } from 'react-animations';
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import * as ROUTES from '../../../../router/routes';
import { colors, device } from '../../../../utils/constants';

const fadeInAimation = keyframes`${fadeIn}`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(25, 25, 25, 1);

  position: fixed;
  z-index: 999;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  animation: 0.7s ${fadeInAimation};
`;

const MenuItem = styled.div`
  color: white;
  font-size: 1.2vw;
  font-family: 'MontserratBold';
  cursor: pointer;

  height: 2.5vw;

  &:hover {
    a {
      color: ${colors.hover};
    }
  }

  a {
    text-decoration: none !important;
    color: white;
    transition: 1s color ease-in-out;
  }

  @media ${device.mobile} {
    height: 40px;

    a {
      font-size: 4em;
    }
  }
`;

const HideMenu = () => {
  const { t } = useTranslation();

  const landingStore = useSelector((state) => state.landing);

  return (
    landingStore.openMenu && (
      <Container>
        <Fade duration={1200}>
          <MenuItem>
            <Link to={ROUTES.NOSOTROS}>{t('menu.nosotro')}</Link>
          </MenuItem>

          <MenuItem>
            <Link to={ROUTES.SERVICIOS}>{t('menu.service')}</Link>
          </MenuItem>
          <MenuItem>
            <Link to={ROUTES.PORTAFOLIO}>{t('menu.portafolio')}</Link>
          </MenuItem>
          <MenuItem>
            <Link to={ROUTES.NEWSLETTER}>{t('menu.news')}</Link>
          </MenuItem>
          <MenuItem>
            <Link to={ROUTES.PRODUCCION}>{t('menu.servicioProduccion')}</Link>
          </MenuItem>
          <MenuItem>
            <Link to={ROUTES.CONTACTO}>{t('menu.contacto')}</Link>
          </MenuItem>
        </Fade>
      </Container>
    )
  );
};

export default HideMenu;
