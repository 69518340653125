import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../../utils/constants';

const Container = styled.div`
  height: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  &:hover .titulo {
    cursor: pointer;
    font-size: 1.2vw;
    transition: 0.5s;
    color: #fcee21;
    font-family: 'FuturaRegular';
  }

  @media ${device.mobile} {
    height: auto;

    &:hover .titulo {
      font-size: 5.1vw;
    }
  }
`;

const Titulo = styled.label`
  font-family: 'FuturaRegular';
  font-size: 1.1vw;
  color: grey;
  line-height: 0.1vw;

  @media ${device.mobile} {
    font-size: 4vw;
    line-height: 6vw;
  }
`;

const Boton = (props) => {
  return (
    <Container>
      <Titulo className="titulo">{props.titulo}</Titulo>
    </Container>
  );
};

export default Boton;
