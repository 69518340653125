import {
  GET_ALL_NEWSLETTER,
  SET_NEWSLETTER_ACTIVO,
  GET_ALL_PUBLICO_NEWSLETTER,
  CLEAR_ALL_PUBLICO_NEWSLETTER,
} from "../types";

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  allPublico: [],
  totalPages: 1,
  currentPage: 1,
};

export const newsletterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NEWSLETTER:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case GET_ALL_PUBLICO_NEWSLETTER:
      return {
        ...state,
        allPublico: action.payload.all,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case CLEAR_ALL_PUBLICO_NEWSLETTER:
      return {
        ...state,
        allPublico: action.payload,
        page: 1,
        totalPages: 1,
        currentPage: 1,
      };
    case SET_NEWSLETTER_ACTIVO:
      return {
        ...state,
        activo: action.payload,
      };
    default:
      return state;
  }
};
