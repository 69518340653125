import React from "react";
import styled from "styled-components";
import FormLogin from "./components/form";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-item: center;
  justify-content: center;
  flex-direction: row;
  background-color: black;
`;

const Login = () => {
  return (
    <Container>
      <FormLogin />
    </Container>
  );
};

export default Login;
