import styled from 'styled-components';
import { device } from '../../../utils/constants';

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
`;

export const Section = styled.div`
  width: 100vw;
  height: ${(props) => props.h};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  margin: ${(props) => props.m};
`;

export const LeyendaBody = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 3em;
`;

export const Leyenda = styled.div`
  width: 24%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  @media ${device.mobile} {
    width: 90%;
  }

  label {
    width: 90%;
    background-color: #fcee21;
    padding: 3px 5px;
    font-family: 'FuturaBold';
  }

  text {
    padding: 5px;
    font-family: 'FuturaBold';
  }
`;

export const Titulo = styled.label`
  font-size: 4vw;
  color: transparent;
  font-family: 'FuturaBold';
  letter-spacing: 0.2vw;
  color: white;

  @media ${device.mobile} {
    line-height: 8vw;
    font-size: 10vw;
  }
`;

export const SubTitulo = styled.label`
  font-size: 1.7vw;
  font-family: 'FuturaLigth';
  color: white;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  margin-top: ${({ mt }) => mt ?? '0px'};

  @media ${device.mobile} {
    font-size: 6vw;
    margin-top: 1.5vw;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: space-around;
  justify-content: center;
  flex-direction: row;
  margin: 10vw 0vw;
  margin-top: ${(props) => props.mt};

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const SectionForm = styled.div`
  width: 35vw;
  height: 45vw;
  display: flex;
  align-items: center;
  flex-direction: column;

  .formlacaja {
    width: 50vw;
  }

  @media ${device.mobile} {
    width: 90vw;
  }
`;

export const BodyPlay = styled.div`
  width: 30vw;
  height: 22vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  margin: 0vw 3vw;

  .formlacaja {
    width: 50vw;
  }

  iframe {
    box-sizing: border-box;
    height: 300px;
  }

  @media ${device.mobile} {
    height: 30vh;

    iframe {
      box-sizing: border-box;
    }
  }
`;

export const Logo = styled.img`
  width: ${({ size }) => size ?? '10vw'};
  padding: 1vw 2vw;
  position: fixed;
  z-index: 999;
`;
