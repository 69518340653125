import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import { archivosFiles } from '../../../../services/api';
import { getAllVideosAction } from '../../../../store/actions/videos.action';
import { device } from '../../../../utils/constants';
import { SectionPublicidad } from '../../publicidad/components';
import PublicidadComponent from '../../publicidad/publicidad';
import LayoutMenu from '../home/layoutHome';
import CheckendWidget from './CheckendWidget';
import useVideosLinksHook from './VideosLinksHook';

const ContainerOptions = styled.div`
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 2em;
  color: white;

  @media ${device.mobile} {
    justify-content: center;
    width: 100vw;
    padding-left: 1vw;
    flex-wrap: wrap;
    margin-top: ${({ mt }) => mt ?? '0.2em'};
    margin-bottom: ${({ mb }) => mb ?? '1em'};
  }
`;

const PortafolioNew = () => {
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ query: device.mobile });

  const location = useLocation();
  const videosStore = useSelector((state) => state.videos);

  const landingStore = useSelector((state) => state.landing);

  const [loading, setLoading] = useState(false);

  const {
    tiposArray,
    categoriasArray,
    selectedsType,
    setSelectedsType,
    selectedsCategories,
    setSelectedsCategories,
  } = useVideosLinksHook();

  const [videos, setVideos] = React.useState([]);

  useEffect(() => {
    const fechData = async () => {
      const { state } = location;
      const { categoria } = state || {};

      if (categoria) {
        new Promise((resolve) => setTimeout(resolve, 500)).then(() => {
          setSelectedsCategories([categoria]);
        });
      }

      setLoading(true);

      const res = await getAllVideosAction(dispatch);

      if (res) {
        setLoading(false);
        setVideos(res);
      }
    };

    fechData();
  }, []);

  useEffect(() => {
    if (loading) return;

    const filteredVideos = videosStore.all.filter((video) => {
      if (
        selectedsType.includes(video.tipo) ||
        video.categorias.some((categoria) =>
          selectedsCategories.includes(categoria)
        )
      ) {
        return video;
      }
    });

    if (selectedsType.length === 0 && selectedsCategories.length === 0) {
      setVideos(videosStore.all);
      return;
    }

    setVideos(filteredVideos);
  }, [loading, selectedsType, selectedsCategories]);

  function CheckboxComponent({
    label,
    all,
    one = false,
    selected,
    setSelected,
    isCategory = false,
    isMobile = false,
  }) {
    const findOne = all.find((el) => el.label === label);

    const checked = one
      ? selected === findOne.id
      : selected.includes(findOne.id);

    const handleChange = () => {
      if (!checked) {
        setSelected((prev) => {
          if (!prev.includes(findOne.id)) {
            return [...prev, findOne.id];
          }
          return prev;
        });
      } else {
        setSelected((prev) => prev.filter((item) => item !== findOne.id));
      }
    };

    return (
      <div className="checkbox-container">
        <CheckendWidget
          active={checked}
          onClick={handleChange}
          isCategory={isCategory}
          isMobile={isMobile}
        />
        <label
          htmlFor="checkbox"
          style={{
            marginLeft: '0.6em',
            fontSize: mobile
              ? isCategory
                ? '0.5em'
                : '0.7em'
              : isCategory
                ? '1em'
                : '1.2em',
            fontFamily: 'MontserratMedium',
            marginRight: isCategory ? 0 : '1em',
          }}
          onClick={handleChange}
        >
          {label.charAt(0).toUpperCase() + label.slice(1)}
        </label>
        <style jsx>{`
          .checkbox-container {
            display: flex;
            align-items: center;
            margin-top: 0.7em;
            margin-right: 0.8em;
          }
        `}</style>
      </div>
    );
  }

  const categoriaMobile = (
    <>
      <ContainerOptions mt="1em" mb="0.2em">
        <CheckboxComponent
          key={categoriasArray[0].id}
          label={categoriasArray[0].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
          isMobile
        />
        <CheckboxComponent
          key={categoriasArray[1].id}
          label={categoriasArray[1].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
          isMobile
        />
        <CheckboxComponent
          key={categoriasArray[2].id}
          label={categoriasArray[2].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
          isMobile
        />
      </ContainerOptions>
      <ContainerOptions mt="0em" mb="2em">
        <CheckboxComponent
          key={categoriasArray[3].id}
          label={categoriasArray[3].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
          isMobile
        />
        <CheckboxComponent
          isMobile
          key={categoriasArray[4].id}
          label={categoriasArray[4].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
        />
        <CheckboxComponent
          key={categoriasArray[5].id}
          isMobile
          label={categoriasArray[5].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
        />
      </ContainerOptions>
    </>
  );

  videos.sort((a, b) => b.posicion - a.posicion);

  return (
    <LayoutMenu>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '5vw',
        }}
      >
        <Fade duration={1500}>
          <ContainerOptions mt="5em">
            {tiposArray.map((tipo) => (
              <CheckboxComponent
                key={tipo.id}
                label={tipo.label}
                mb="0em"
                all={tiposArray}
                selected={selectedsType}
                setSelected={setSelectedsType}
                isMobile
              />
            ))}
          </ContainerOptions>
          {mobile ? (
            categoriaMobile
          ) : (
            <ContainerOptions>
              {categoriasArray.map((categoria) => (
                <CheckboxComponent
                  key={categoria.id}
                  label={categoria.label}
                  all={categoriasArray}
                  selected={selectedsCategories}
                  setSelected={setSelectedsCategories}
                  isCategory
                />
              ))}
            </ContainerOptions>
          )}
        </Fade>

        {loading && (
          <Spinner size="lg" style={{ marginRight: '10px', color: 'white' }} />
        )}

        <SectionPublicidad>
          {videos.map((el, index) => {
            return (
              <PublicidadComponent
                key={index}
                title={el.titulo}
                desc={el.desc}
                url={landingStore.lang === 'es' ? el.link : el.linkEN}
                img={
                  landingStore.lang === 'es'
                    ? `${archivosFiles}/${el.image?.folder}/${el.image?.nombre}`
                    : `${archivosFiles}/${el.imageEN?.folder}/${el.imageEN?.nombre}`
                }
              />
            );
          })}
        </SectionPublicidad>
      </div>
    </LayoutMenu>
  );
};

export default PortafolioNew;
