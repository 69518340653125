import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../../../../../utils/constants';
import Boton from './boton';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: Column;
  justify-content: center;
  align-items: flex-start;

  a {
    text-decoration: none;
  }
`;

const Titulo = styled.label`
  font-family: 'FuturaMedium';
  font-size: 1.1vw;
  color: white;
  margin-bottom: 3.7vw;

  @media ${device.mobile} {
    font-size: 5vw;
    margin-top: 5vw;
  }
`;

const Botones = ({ t }) => {
  return (
    <Container>
      <Titulo>{t('footer.service')}</Titulo>
      <Link to="/cine">
        <Boton titulo={t('footer.botons.cine')} />
      </Link>
      <Link to="/publicidad">
        <Boton titulo={t('footer.botons.adv')} />
      </Link>
      <Link to="/propaganda">
        <Boton titulo={t('footer.botons.pro')} />
      </Link>
    </Container>
  );
};

export default Botones;
