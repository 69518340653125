import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';
import { MIS_VIDEOS } from '../../../common/const';
import {
  SET_IS_LEYENDA,
  SET_IS_LEYENDA_IMG,
  SET_IS_SUSCRIPTION,
} from '../../../store/types';
import { device } from '../../../utils/constants';
import MenuHome from './home/MenuHome';
import LogoComponent from './home/components/Logo';
import LayoutMenu from './home/layoutHome';

export const Title = styled.div`
  color: white;
  font-size: 1.3vw;
  font-family: 'MontserratLight';
  margin-top: 1.4vw;
  text-align: ${(center) => (center ? 'center' : 'right')};

  @media ${device.mobile} {
    font-size: ${({ fs }) => fs ?? '5vw'};
    line-height: 1.1em;
  }
`;

export const SubTitle = styled.div`
  color: white;
  font-size: 1vw;
  font-family: 'MontserratBold';

  @media ${device.mobile} {
    margin-top: 1em;
    font-size: 3vw;
  }
`;

const WebNewsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const landingStore = useSelector((state) => state.landing);

  const handleWindowClose = () => {
    dispatch({
      type: SET_IS_SUSCRIPTION,
      payload: true,
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose);

    const fetchData = () => {
      const x = localStorage.getItem(MIS_VIDEOS);
      if (x === undefined || x === null) {
        localStorage.setItem(MIS_VIDEOS, false);
      }
      dispatch({
        type: SET_IS_LEYENDA,
        payload: false,
      });
      dispatch({
        type: SET_IS_LEYENDA_IMG,
        payload: false,
      });

      localStorage.removeItem('PLPtoken');
      localStorage.removeItem('persist:PLP');

      setTimeout(() => {
        dispatch({
          type: SET_IS_SUSCRIPTION,
          payload: false,
        });
      }, 200);
    };
    fetchData();
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, []);

  return (
    <LayoutMenu isLogoTop={false}>
      <Fade duration={2000}>
        <LogoComponent />
        <Title fs={landingStore.lang === 'en' ? null : '5vw'}>
          {t('home.text1')}
        </Title>
        <SubTitle>{t('home.text2')}</SubTitle>
      </Fade>
      <MenuHome />
    </LayoutMenu>
  );
};

export default WebNewsPage;
