import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import styled from 'styled-components';
import {
  getAllTiposBoletinAction,
  setActivoTiposBoletinAction,
} from '../../../../store/actions';
import {
  IS_FORM_TIPOS_BOLETIN,
  IS_LIST_TIPOS_BOLETIN,
} from '../../../../store/types';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .botones {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;

const ListadoTiposBoletin = () => {
  const dispatch = useDispatch();

  const tiposBoletinState = useSelector((state) => state.tiposBoletin);

  useEffect(() => {
    const fetchData = async () => {
      await getAllTiposBoletinAction(dispatch, null);
    };
    fetchData();
  }, []);

  return (
    <Container>
      <Row style={{ marginTop: '10px' }}>
        {tiposBoletinState.all.map((el, i) => {
          return (
            <Col key={i} md={3}>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">{el.nombre}</CardTitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    <label style={{ color: el.isActivo ? 'green' : 'red' }}>
                      {el.isActivo ? 'Activo' : 'Bloqueado'}
                    </label>
                  </CardSubtitle>
                  <Button
                    onClick={() => {
                      setActivoTiposBoletinAction(dispatch, el);
                      dispatch({
                        type: IS_LIST_TIPOS_BOLETIN,
                        payload: false,
                      });
                      dispatch({
                        type: IS_FORM_TIPOS_BOLETIN,
                        payload: false,
                      });
                    }}
                  >
                    Ver
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      setActivoTiposBoletinAction(dispatch, el);
                      dispatch({
                        type: IS_FORM_TIPOS_BOLETIN,
                        payload: true,
                      });
                      dispatch({
                        type: IS_LIST_TIPOS_BOLETIN,
                        payload: true,
                      });
                    }}
                    style={{ marginLeft: '10px' }}
                  >
                    Editar
                  </Button>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ListadoTiposBoletin;
