import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../../../utils/constants';
import { openInNewTab } from '../landing-page/navbar/menu_mobile';

const FooterServicios = ({ name, text, foto }) => {
  const mobile = useMediaQuery({ query: device.mobile });
  const layoutState = useSelector((state) => state.layout);
  let history = useHistory();

  const textEs = (
    <>
      <div>
        <Titulo
          style={{
            lineHeight: mobile ? null : '10vw',
          }}
        >
          HAY MÁS
        </Titulo>
        <label
          style={{
            fontSize: mobile ? '5vw' : '2vw',
            fontFamily: 'FuturaMedium',
            lineHeight: mobile ? '5vw' : '3vw',
            textAlign: mobile ? 'center' : null,
          }}
        >
          HISTORIAS HUMANAS, <br />
          BIEN CONTADAS
        </label>
      </div>
      <div>
        <center>
          <img
            src="../../img/diciembre2024/logoblack.png"
            style={{
              width: mobile ? '40vw' : '20vw',
              margin: mobile ? '5vw' : null,
            }}
            alt="imagelogoblack"
          />
        </center>
      </div>
    </>
  );

  const textEn = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          fontFamily: 'FuturaBold',
          fontSize: mobile ? '19vw' : '10vw',
          lineHeight: mobile ? '13vw' : '7vw',
        }}
      >
        THERE’S
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            fontFamily: 'FuturaBold',
            fontSize: mobile ? '19vw' : '10vw',
            lineHeight: mobile ? '38vw' : '16vw',
          }}
        >
          MORE
        </div>
        <div>
          <img
            src="../../img/diciembre2024/logoblack.png"
            style={{
              width: mobile ? '20vw' : '9vw',
              marginLeft: '2vw',
            }}
            alt="imagelogoblack"
          />
        </div>
      </div>
      <div
        style={{
          fontSize: mobile ? '5vw' : '2vw',
          fontFamily: 'FuturaMedium',
          lineHeight: mobile ? '5vw' : '3vw',
          textAlign: mobile ? 'center' : null,
        }}
      >
        <label>HUMAN STORIES,</label>
        <br />
        <label>WELL TOLD</label>
      </div>
    </div>
  );

  return (
    <Body>
      {layoutState.lang === 'es' ? textEs : textEn}
      <div
        style={{
          display: 'flex',
          height: mobile ? '30vw' : '27vw',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          paddingLeft: '2vw',
        }}
      >
        <Web
          onClick={() => {
            history.push('/');
          }}
        >
          PALOSPANAS.COM
        </Web>
        <Instagram
          onClick={() => {
            openInNewTab(
              'https://instagram.com/palospanasprod?igshid=1t0x78sa705f0'
            );
          }}
        >
          @PALOSPANASPROD
        </Instagram>
      </div>
    </Body>
  );
};

export default FooterServicios;

const Body = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 8vw 10vw;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 15vw 0;
    justify-content: center;
    text-align: center;
  }
`;

const Titulo = styled.label`
  font-size: 10vw;
  font-family: FuturaBold;
  color: black;

  @media ${device.mobile} {
    font-size: 15vw;
  }
`;

const Web = styled.label`
  font-size: 3vw;
  font-family: FuturaBold;
  line-height: 3vw;
  border-bottom: 3px solid black;

  &:hover {
    border-bottom: 3px solid #fcee21;
    color: #fcee21;
    cursor: pointer;
  }

  @media ${device.mobile} {
    text-align: center;
    font-size: 7vw;
  }
`;

const Instagram = styled.label`
  font-family: FuturaBold;
  font-size: 2.4vw;
  margin-top: 1vw;

  &:hover {
    color: #fcee21;
    cursor: pointer;
  }

  @media ${device.mobile} {
    text-align: center;
    font-size: 6vw;
  }
`;
