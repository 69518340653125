import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { colors, device } from '../../../../utils/constants';
import { MenuItem } from '../home/MenuHome';
import LayoutMenu from '../home/layoutHome';

const Table = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  margin-left: 15vw;

  @media ${device.mobile} {
    margin-left: 0;
    width: 100vw;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3em;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Titulo = styled.div`
  font-size: 1.3vw;
  font-family: 'MontserratBold';
  cursor: pointer;

  transition: 0.5s color ease-in-out;

  &:hover {
    color: ${colors.hover};
  }

  @media ${device.mobile} {
    font-size: 2.5em;

    &:hover {
      color: ${colors.hover};
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: white;
  text-align: start;
  font-family: 'MontserratLight';
  font-size: 1.3vw;

  label {
    font-size: 1.2vw;
  }

  @media ${device.mobile} {
    text-align: start;
    margin-top: 3em;

    h3 {
      font-size: 2em;
    }

    label {
      font-size: 2.5em;
    }
  }
`;

const ServiciosNew = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const mobile = useMediaQuery({ query: device.mobile });

  const info1 = [
    {
      id: 'concepto',
      title: t('portafolio.concepto.title'),
      items: [
        t('portafolio.concepto.creatividad'),
        t('portafolio.concepto.identidad'),
        t('portafolio.concepto.identidadVisual'),
        t('portafolio.concepto.tono'),
      ],
    },
    {
      id: 'estrategia',
      title: t('portafolio.estrategia.title'),
      items: [
        t('portafolio.estrategia.diagnostico'),
        t('portafolio.estrategia.vision'),
        t('portafolio.estrategia.proposito'),
        t('portafolio.estrategia.posicionamiento'),
        t('portafolio.estrategia.alianzas'),
      ],
    },
    {
      id: 'ejecucion',
      title: t('portafolio.ejecucion.title'),
      items: [
        t('portafolio.ejecucion.guion'),
        t('portafolio.ejecucion.produccion'),
        t('portafolio.ejecucion.postproduccion'),
        t('portafolio.ejecucion.disenoGrafico'),
        t('portafolio.ejecucion.lanzamientos'),
        t('portafolio.ejecucion.curaduriaContenido'),
      ],
    },
  ];

  const info2 = [
    {
      id: 'comunicacion',
      title: t('portafolio.comunicaciones.title'),
      items: [
        t('portafolio.comunicaciones.medios'),
        t('portafolio.comunicaciones.prensa'),
        t('portafolio.comunicaciones.redesSociales'),
        t('portafolio.comunicaciones.reputacion'),
      ],
    },
    {
      id: 'experiencia',
      title: t('portafolio.experiencias.title'),
      items: [
        t('portafolio.experiencias.activaciones'),
        t('portafolio.experiencias.desarrolloDiseno'),
        t('portafolio.experiencias.produccionLogistica'),
        t('portafolio.experiencias.coberturaAudiovisual'),
        t('portafolio.experiencias.alianzasEstrategicas'),
      ],
    },
    {
      id: 'talento',
      title: t('portafolio.talentos.title'),
      items: [
        t('portafolio.talentos.casting'),
        t('portafolio.talentos.relacionesPublicas'),
        t('portafolio.talentos.curaduria'),
        t('portafolio.talentos.influenciadores'),
      ],
    },
  ];

  const mobileWidget = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '6em',
        width: '100%',
      }}
    >
      <div
        style={{
          width: '55%',
        }}
      >
        {[...info1, ...info2].map((column) => (
          <Column>
            <Fade duration={1500}>
              <Titulo
                onClick={() => {
                  history.push('/portafolio');
                }}
              >
                {column.title}
              </Titulo>
              {column.items.map((item) => (
                <label>{item}</label>
              ))}
            </Fade>
          </Column>
        ))}
      </div>
      <Fade delay={500} duration={1500}>
        <MenuItem
          color="white"
          fs=".5em"
          onClick={() => {
            history.push('/portafolio');
          }}
        >
          {t('menu.portafolio')} plp
        </MenuItem>
      </Fade>
    </div>
  );

  return (
    <LayoutMenu>
      {mobile ? (
        mobileWidget
      ) : (
        <>
          <Table>
            <Row>
              {info1.map((column) => (
                <Column>
                  <Fade duration={1500}>
                    <Titulo
                      onClick={() => {
                        history.push('/portafolio', {
                          categoria: column.id,
                        });
                      }}
                    >
                      {column.title}
                    </Titulo>
                    {column.items.map((item) => (
                      <label>{item}</label>
                    ))}
                  </Fade>
                </Column>
              ))}
            </Row>
            <Row>
              {info2.map((column) => (
                <Column>
                  <Fade delay={300} duration={1500}>
                    <Titulo
                      onClick={() => {
                        history.push('/portafolio', {
                          categoria: column.id,
                        });
                      }}
                    >
                      {column.title}
                    </Titulo>
                    {column.items.map((item) => (
                      <label>{item}</label>
                    ))}
                  </Fade>
                </Column>
              ))}
            </Row>
          </Table>
          <div
            style={{
              marginTop: '3em',
              marginBottom: mobile ? '5em' : '0em',
            }}
          >
            <Fade delay={500} duration={1500}>
              <MenuItem
                color="white"
                fs="1.3vw"
                onClick={() => {
                  history.push('/portafolio');
                }}
              >
                {t('menu.portafolio')} plp
              </MenuItem>
            </Fade>
          </div>
        </>
      )}
    </LayoutMenu>
  );
};

export default ServiciosNew;
