import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Spinner } from 'reactstrap';
import styled from 'styled-components';
import { sendMensajeWebSuscripcionesService } from '../../../../../services';

// Styled components
const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    width: ${(props) => (props.isMobile ? '85vw' : '55vw')};
  }
`;

const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ImageContainer = styled.div`
  width: ${(props) => (props.isMobile ? '85vw' : '55vw')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  position: absolute;
  bottom: ${(props) => (!props.error ? '1.6vw' : '0.5vw')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputGroup = styled.div`
  width: ${(props) => (props.isMobile ? '80vw' : '30vw')};
  display: flex;
  flex-direction: ${(props) => (!props.isMobile ? 'row' : 'column')};
  background-color: white;
  padding: 0.2em;
`;

function ModalSuscripcion({ isOpen, toggle, isMobile }) {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [send, setSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const res = await sendMensajeWebSuscripcionesService({
        fullname: 'user invited',
        email,
      });

      if (res.statusCode === 200) {
        setLoading(false);
        setSend(true);
        setTimeout(() => {
          setEmail('');
          toggle();
          setSend(false);
        }, 1500);
      } else {
        setLoading(false);
        setError(t('modalSuscripcion.emailExists'));
        setTimeout(() => {
          setError('');
        }, 2000);
      }
    } catch (err) {
      setLoading(false);
      setError(t('modalSuscripcion.error'));
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      centered
      isMobile={isMobile}
    >
      <ModalContainer>
        <ImageContainer isMobile={isMobile}>
          <img
            src="./img/diciembre2024/popUp_v2.jpg"
            alt="Suscripción"
            style={{
              height: isMobile ? '60vh' : null,
              width: '100%',
              objectFit: isMobile ? 'cover' : 'contain',
            }}
          />
        </ImageContainer>
        <FormContainer error={error}>
          {loading && (
            <>
              <Spinner size="sm" color="white" />
              <label
                style={{
                  fontSize: '1em',
                  fontFamily: 'MontserratMedium',
                  color: 'white',
                }}
              >
                {t('modalSuscripcion.sending')}
              </label>
            </>
          )}
          {send ? (
            <p
              style={{
                fontSize: '1.2em',
                fontFamily: 'MontserratMedium',
                color: 'white',
                marginTop: '1.5em',
                opacity: send ? 1 : 0,
                transition: 'opacity 0.5s ease',
              }}
            >
              {t('modalSuscripcion.success')}
            </p>
          ) : (
            !loading && (
              <form onSubmit={handleSubmit}>
                <InputGroup isMobile={isMobile}>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder={t('modalSuscripcion.email')}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      borderRadius: '0',
                      width: '100%',
                      fontSize: !isMobile ? '1.3vw' : '1.3em',
                      fontFamily: 'MontserratLight',
                      border: 'none',
                      boxShadow: 'none',
                      padding: '0.2em',
                      paddingLeft: '0.5em',
                      textAlign: isMobile ? 'center' : 'left',
                    }}
                  />
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      borderRadius: '0',
                      backgroundColor: '#000',
                      color: '#fff',
                      width: isMobile ? '100%' : '20vw',
                      fontSize: '1.1em',
                      fontFamily: 'MontserratLight',
                      padding: '0.1em',
                    }}
                  >
                    {t('modalSuscripcion.send')}
                  </button>
                </InputGroup>
              </form>
            )
          )}
          {error && (
            <div
              style={{
                fontSize: '0.8em',
                fontFamily: 'MontserratLight',
                color: 'white',
              }}
            >
              {error}
            </div>
          )}
        </FormContainer>
      </ModalContainer>
    </StyledModal>
  );
}

export default ModalSuscripcion;
