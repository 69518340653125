import React, { useState } from 'react';
import { BsPlayCircle } from 'react-icons/bs';
import { Modal } from 'reactstrap';
import styled from 'styled-components';
import { device } from '../../../utils/constants';
import { SectionVideo } from '../blog/components';

const Playing = styled.a`
  cursor: pointer;

  &:hover {
  }

  @media ${device.mobile} {
  }
`;

const SectionVideoComponent = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <SectionVideo url="img/propaganda/video-1.JPG">
      <Playing onClick={toggle}>
        <BsPlayCircle
          style={{
            color: 'white',
            width: '4em',
            height: '4em',
          }}
        />
      </Playing>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="lg"
        className="modal-blog-video"
      >
        <center>
          <figure
            style={{
              left: 0,
              width: '100%',
              height: 0,
              position: 'relative',
              paddingBottom: '52.73%',
              marginBlockEnd: 0,
              marginBlockStart: 0,
              marginInlineStart: 0,
              marginInlineEnd: 0,
            }}
          >
            <iframe
              title="video"
              id="pv_n5l6WG3y"
              src="https://media.publit.io/file/Propaganda/MUD2021/Aqui-Estamos-1-1min.html?player=public01&at=eyJpdiI6Im1JWnpCTEM4Mm80bVlSTkpKYnpDUlE9PSIsInZhbHVlIjoiUjdsNnIyQm0rSGdSeXRUVnp0TFZEQ2FNWWs3ZWxDekJTSnJTZmx5NUdtUT0iLCJtYWMiOiI5ZThhZTcwY2M2OTdmYzQxY2M1MjUyYzRlYjkxMWZkOWU4YzhkN2RkMzFmYmYwYjVmZDBmNzQ3NDk1ZTIwOWQ2IiwidGFnIjoiIn0="
              scrolling="no"
              style={{
                border: 0,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                position: 'absolute',
                overflow: 'hidden',
              }}
              allowFullScreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            />
          </figure>
        </center>
      </Modal>
    </SectionVideo>
  );
};

export default SectionVideoComponent;
