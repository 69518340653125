import styled from "styled-components";
import { device } from "../../../../../utils/constants";

const Logo = styled.img`
  width: 10vw;
  padding: 1vw 2vw;
  position: fixed;
  z-index: 999;

  cursor: pointer;

  @media ${device.mobile} {
    padding: 5vw;
    width: 25% !important;
    height: auto;
  }
`;

export default Logo;
