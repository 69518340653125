import React, { useState } from 'react';
import { zoomIn } from 'react-animations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as Linka, useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
import styled, { keyframes } from 'styled-components';
import * as CONST from '../../../../common/const';
import * as ROUTES from '../../../../router/routes';
import {
  SET_IS_LEYENDA_IMG,
  SET_SECTION_LANDING,
} from '../../../../store/types';
import { Button } from './components';
import { Menu } from './navbar';

const slideRightAnimation = keyframes`${zoomIn}`;

const ContainerHover = styled.div`
  width: 100vw;
  position: fixed;
  z-index: 900;
  height: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImagenCaleta = styled.div`
  position: fixed;
  top: 6em;
  width: 25vw;
  animation: 1.5s ${slideRightAnimation};
`;

const MenuHide = ({
  menuHover,
  onClickHeader,
  onClickPublicidad,
  onClickRelevante,
  onClickLaCaja,
  verMenuHover,
  section,
  isLanding,
  isSmooth,
  srcImg,
}) => {
  const { t } = useTranslation();

  let location = useLocation();
  const dispatch = useDispatch();
  const layoutState = useSelector((state) => state.layout);
  const [ver, setVer] = useState(false);

  const homeRoute = isLanding ? (
    <Link to={CONST.HEADER} spy smooth duration={800} onClick={onClickHeader}>
      <Button activo={section === CONST.HEADER}>{t('menu.home')}</Button>
    </Link>
  ) : (
    <Linka to={ROUTES.LANDING_PAGE}>
      <Button
        onClick={() => {
          dispatch({
            type: SET_SECTION_LANDING,
            payload: CONST.HEADER,
          });
        }}
      >
        {' '}
        {t('menu.home')}
      </Button>
    </Linka>
  );

  const caletaRoute =
    isLanding || isSmooth ? (
      <Link
        to={CONST.LA_CAJA}
        spy
        smooth
        duration={800}
        onClick={onClickLaCaja}
      >
        <Button
          activo={section === CONST.LA_CAJA}
          siempre
          isHover
          onMouseEnter={() => {
            dispatch({
              type: SET_IS_LEYENDA_IMG,
              payload: true,
            });
          }}
          onMouseLeave={() => {
            dispatch({
              type: SET_IS_LEYENDA_IMG,
              payload: false,
            });
          }}
        >
          {t('menu.caja')}
        </Button>
      </Link>
    ) : (
      <Linka to={ROUTES.LANDING_PAGE}>
        <Button
          onClick={() => {
            dispatch({
              type: SET_SECTION_LANDING,
              payload: CONST.LA_CAJA,
            });
          }}
          onMouseEnter={() => {
            dispatch({
              type: SET_IS_LEYENDA_IMG,
              payload: true,
            });
          }}
          onMouseLeave={() => {
            dispatch({
              type: SET_IS_LEYENDA_IMG,
              payload: false,
            });
          }}
        >
          {' '}
          {t('menu.caja')}
        </Button>
      </Linka>
    );

  return (
    <ContainerHover
      onMouseEnter={() => {
        menuHover ? menuHover(true) : setVer(true);
      }}
      onMouseLeave={() => {
        menuHover ? menuHover(false) : setVer(false);
      }}
    >
      <Menu show={verMenuHover ?? ver}>
        {homeRoute}

        <Linka to={ROUTES.NOSOTROS}>
          <Button
            style={{
              color: location.pathname === ROUTES.NOSOTROS ? '#FCEE21' : '#fff',
            }}
          >
            {t('menu.nosotro')}
          </Button>
        </Linka>

        {caletaRoute}

        <Linka to={ROUTES.NEWSLETTER}>
          <Button
            style={{
              color:
                location.pathname === ROUTES.NEWSLETTER ? '#FCEE21' : '#fff',
            }}
          >
            {t('menu.news')}
          </Button>
        </Linka>
        <Linka to={ROUTES.SERVICIOS}>
          <Button
            style={{
              color:
                location.pathname === ROUTES.SERVICIOS ? '#FCEE21' : '#fff',
            }}
          >
            {t('menu.service')}
          </Button>
        </Linka>
        <a href="mailto:contacto@palospanas.com">
          <Button>{t('menu.aplica')}</Button>
        </a>
      </Menu>
      {layoutState.isLeyendaImg && (
        <ImagenCaleta>
          <img width="100%" src="../../img/imgCaleta.png" alt="caleta" />
        </ImagenCaleta>
      )}
    </ContainerHover>
  );
};

export default MenuHide;
