import axios from "axios";
import * as API from "./api";

export const getAllPublicoTiposBoletinService = async ({ url, params }) => {
  try {
    const res = await axios.get(url ?? `${API.tiposBoletin}/publico`, {
      params,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllTiposBoletinService = async (page) => {
  try {
    const res = await axios.get(API.tiposBoletin, {
      headers: await API.Token({}),
      params: { page },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const saveTiposBoletinService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.patch(`${API.tiposBoletin}/${id}`, data, {
      headers: await API.Token({ isToken: true }),
    });
  } else {
    res = await axios.post(API.tiposBoletin, data, {
      headers: await API.Token({ isToken: true }),
    });
  }
  return res.data;
};

export const deleteTiposBoletinService = async (id) => {
  try {
    const res = await axios.delete(`${API.tiposBoletin}/${id}`, {
      headers: await API.Token({}),
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
