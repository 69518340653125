import React from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { history } from '../helpers';
import { Login } from '../pages/admin/auth';
import { Clientes } from '../pages/admin/clientes';
import { Dashboard } from '../pages/admin/dashboard';
import { Inicio } from '../pages/admin/inicio';
import { Sidebar } from '../pages/admin/layout';
import { Newsletter } from '../pages/admin/newsletter';
import { Suscripciones } from '../pages/admin/suscripciones';
import PageCine from '../pages/web/cine';
import PageContacto from '../pages/web/contacto';
import PagePropaganda from '../pages/web/propaganda';

import * as ROUTES from './routes';

import { MIS_VIDEOS } from '../common/const';

import GobiernoPage from '../pages/web/propaganda/GobiernoPage';
import MudPage from '../pages/web/propaganda/MudPage';
import PlanPage from '../pages/web/propaganda/PlanPage';
import SpotsPage from '../pages/web/spots';

import { VideosAdmin } from '../pages/admin/videos';
import WebNewsPage from '../pages/web/landing-news';
import ContactoNew from '../pages/web/landing-news/contacto';
import NewsletterNew from '../pages/web/landing-news/newsletter';
import NosotrosNew from '../pages/web/landing-news/nosotros';
import PortafolioNew from '../pages/web/landing-news/portafolio';
import ProduccionNew from '../pages/web/landing-news/produccion';
import ServiciosNew from '../pages/web/landing-news/servicios';

const publicViews = [
  {
    path: ROUTES.LOGIN,
    component: Login,
  },
  {
    path: ROUTES.LANDING_PAGE,
    component: WebNewsPage,
  },
  {
    path: ROUTES.LOGIN,
    component: Login,
  },
  {
    path: ROUTES.CINE,
    component: PageCine,
  },
  {
    path: ROUTES.PROPAGANDA,
    component: PagePropaganda,
  },
  {
    path: ROUTES.APLICA,
    component: PageContacto,
  },
  {
    path: ROUTES.NOSOTROS,
    component: NosotrosNew,
  },
  {
    path: ROUTES.SERVICIOS,
    component: ServiciosNew,
  },
  {
    path: ROUTES.NEWSLETTER,
    component: NewsletterNew,
  },
  {
    path: ROUTES.PRODUCCION,
    component: ProduccionNew,
  },
  {
    path: ROUTES.PORTAFOLIO,
    component: PortafolioNew,
  },
  {
    path: ROUTES.CONTACTO,
    component: ContactoNew,
  },
];

const Routers = () => {
  return (
    <Router history={history}>
      <Switch>
        {publicViews.map((view, index) => (
          <Route
            key={index}
            path={view.path}
            exact
            component={view.component}
          />
        ))}
        <PrivateVideoPage path={ROUTES.GOBIERNO}>
          <GobiernoPage />
        </PrivateVideoPage>
        <PrivateVideoPage path={ROUTES.MUD}>
          <MudPage />
        </PrivateVideoPage>
        <PrivateVideoPage path={ROUTES.PLAN}>
          <PlanPage />
        </PrivateVideoPage>
        <PrivateVideoPage path={ROUTES.SPOTS}>
          <SpotsPage />
        </PrivateVideoPage>
        <PrivatePage path={ROUTES.ADMIN} component={Inicio}>
          <Sidebar>
            <Switch>
              <Route exact path={ROUTES.ADMIN}>
                <Dashboard />
              </Route>
              <PrivatePage path="/admin/dashboard">
                <Dashboard />
              </PrivatePage>
              <PrivatePage path="/admin/clientes">
                <Clientes />
              </PrivatePage>
              <PrivatePage path="/admin/newsletter">
                <Newsletter />
              </PrivatePage>
              <PrivatePage path="/admin/suscripciones">
                <Suscripciones />
              </PrivatePage>
              <PrivatePage path="/admin/videos">
                <VideosAdmin />
              </PrivatePage>
            </Switch>
          </Sidebar>
        </PrivatePage>
      </Switch>
    </Router>
  );
};

const validarToken = () => {
  const token = localStorage.getItem('PLPtoken');
  let isValid = false;
  try {
    isValid = token && token !== '';
  } catch (error) {
    return false;
  }
  return isValid;
};

const PrivatePage = ({ children, ...props }) => {
  let isAuthenticated = validarToken();

  return isAuthenticated ? (
    <Route {...props}> {children} </Route>
  ) : (
    <Redirect to={ROUTES.LOGIN} />
  );
};

const PrivateVideoPage = ({ children, ...props }) => {
  const x = localStorage.getItem(MIS_VIDEOS);

  let isAuthenticated = x === 'true';

  return isAuthenticated ? (
    <Route {...props}> {children} </Route>
  ) : (
    <Redirect to={ROUTES.PROPAGANDA} />
  );
};

export default Routers;
