import {
  deleteNewsletterService,
  getAllNewsletterService,
  getAllPublicoNewsletterService,
  saveArchivosService,
  updateNewsletterService,
} from '../../services';
import {
  GET_ALL_NEWSLETTER,
  GET_ALL_PUBLICO_NEWSLETTER,
  SET_NEWSLETTER_ACTIVO,
} from '../types';

export const getAllNewsletterAction = async (dispatch, params) => {
  try {
    const res = await getAllNewsletterService(params);
    if (res.status === 200) {
      dispatch({
        type: GET_ALL_NEWSLETTER,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    return false;
  }
};

export const getAllPublicoNewsletterAction = async (dispatch, params) => {
  try {
    const res = await getAllPublicoNewsletterService(params);

    dispatch({
      type: GET_ALL_PUBLICO_NEWSLETTER,
      payload: {
        all:
          res.data.data.length === 0
            ? []
            : res.data.data.sort((a, b) => a.posicion - b.posicion),
        page: params.page,
        totalPagesArt: res.data.meta.totalPages,
        currentPageArt: res.data.meta.currentPage,
      },
    });
    return res.status === 200;
  } catch (error) {
    return false;
  }
};

export const saveNewsletterAction = async (
  dispatch,
  data,
  fileGif,
  filePng,
  params
) => {
  try {
    const resGif = await saveArchivosService(fileGif, { idNewsletter: 'true' });
    if (filePng) {
      await saveArchivosService(filePng, {
        idNewsletter: resGif.data.newsletter.id,
        idGif: resGif.data.data.id,
      });
    }
    const res = await updateNewsletterService(resGif.data.newsletter.id, data);
    alert(res.message);
    await getAllNewsletterAction(dispatch, params);
    return res.statusCode === 201;
  } catch (error) {
    alert(error);
    console.log(error);
  }
};

export const updateNewsletterAction = async (dispatch, id, data, params) => {
  try {
    const res = await updateNewsletterService(id, data);
    alert(res.message);
    await getAllNewsletterAction(dispatch, params);
    return res.statusCode === 201;
  } catch (error) {
    alert(error);
    console.log(error);
  }
};

export const setActivoNewsletterAction = async (dispatch, data) => {
  dispatch({
    type: SET_NEWSLETTER_ACTIVO,
    payload: data,
  });
};

export const deleteNewsletterAction = async (dispatch, id, params) => {
  const res = await deleteNewsletterService(id);
  await getAllNewsletterAction(dispatch, null, params);
  alert(res.message);
  return res;
};
