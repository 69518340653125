import axios from "axios";
import * as API from "./api";

export const getAllPublicoNewsletterService = async ({
  url,
  page,
  tipoBoletin,
}) => {
  try {
    const res = await axios.get(url ?? API.newsletterPublico, {
      params: {
        page: Number(page),
        tipoBoletin,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllNewsletterService = async (params) => {
  try {
    const res = await axios.get(API.newsletter, {
      headers: await API.Token({}),
      params,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllPageNewsletterService = async (params) => {
  try {
    const res = await axios.get(API.newsletter, {
      headers: await API.Token({}),
      params,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateNewsletterService = async (id, data) => {
  try {
    let res = await axios.patch(`${API.newsletter}/${id}`, data, {
      headers: await API.Token({}),
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const deleteNewsletterService = async (id) => {
  try {
    const res = await axios.delete(`${API.newsletter}/${id}`, {
      headers: await API.Token({}),
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
