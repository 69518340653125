import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { device } from '../../../utils/constants';
import Footer from '../landing-page/footer/footer';
import { Logo } from '../landing-page/navbar/components';
import BotonLang from '../landing-page/navbar/components/boton_lang';
import MenuHide from '../landing-page/navbar/menu_hide';
import MenuMobile from '../landing-page/navbar/menu_mobile';
import { ButtonIcon, ButtonMenu } from '../landing-page/navbar/navbar';
import {
  Container,
  Logo as LogoCom,
  Section,
  SubTitulo,
  Titulo,
} from './components';

const LayoutNosotros = ({
  children,
  title,
  subTitle,
  titlePage,
  isWhiteLang,
  isActiveMenu,
  isSmooth,
  logo,
  noLogo = false,
  srcImg,
  notHeader = false,
  footer = true,
  menuBlack = false,
}) => {
  useEffect(() => window.scrollTo(0, 0), []);

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isOpenMenuMobile ? 'hidden' : '';
  }, [isOpenMenuMobile]);

  return (
    <>
      {isMobileDevice && !isOpenMenuMobile ? null : isOpenMenuMobile ? (
        <Logo src={logo ?? 'img/diciembre2024/logoblack.png'} />
      ) : (
        !noLogo && (
          <Link to={'/'}>
            <LogoCom src={logo ?? 'img/diciembre2024/logoblack.png'} />
          </Link>
        )
      )}
      <MenuHide isSmooth={isSmooth} srcImg={srcImg} />
      <Container>
        {notHeader ? null : (
          <Section id="sectionTitulo" h={isMobileDevice ? '60vw' : '25vw'}>
            <Titulo data-aos="fade-up" data-aos-duration="1000">
              {title}
            </Titulo>
            <SubTitulo
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              {subTitle}
            </SubTitulo>
            {titlePage ? (
              <SubTitulo
                bold
                mt="20px"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="600"
              >
                {titlePage}
              </SubTitulo>
            ) : null}
          </Section>
        )}
        {children}
        {footer && <Footer isHome srcImg={srcImg} />}
        <BotonLang isWhite={isWhiteLang} />
        {isMobileDevice ? (
          !isOpenMenuMobile ? null : (
            <MenuMobile
              vista={isActiveMenu}
              onmenu={() => {}}
              srcImg={srcImg}
            />
          )
        ) : null}
        {isMobileDevice ? (
          <ButtonMenu
            onClick={() => {
              setIsOpenMenuMobile(!isOpenMenuMobile);
            }}
          >
            <ButtonIcon
              src={
                `${srcImg ?? 'img'}` +
                `/${
                  isOpenMenuMobile
                    ? 'closeWhite.png'
                    : menuBlack
                      ? 'menuBlack.png'
                      : 'menuWhite.png'
                }`
              }
            />
          </ButtonMenu>
        ) : null}
      </Container>
    </>
  );
};

export default LayoutNosotros;
