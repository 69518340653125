import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../../../utils/constants';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: Column;
  justify-content: center;
  align-items: flex-start;
`;

const Titulo = styled.label`
  font-family: 'FuturaMedium';
  font-size: 1.1vw;
  color: white;
  margin-bottom: 3vw;

  @media ${device.mobile} {
    font-size: 5vw;
    margin-top: 5vw;
  }
`;

const Info = styled.label`
  font-family: 'FuturaRegular';
  font-size: 1.1vw;
  color: grey;

  ${(props) =>
    props.to &&
    css`
      cursor: pointer;
      &:hover {
        color: #fcee21;
        font-size: 1.2vw;
      }
    `};

  @media ${device.mobile} {
    font-size: 4vw;

    ${(props) =>
      props.to &&
      css`
        &:hover {
          font-size: 4.2vw;
        }
      `};
  }
`;

const Contacto = ({ t }) => {
  return (
    <Container>
      <Titulo>{t('footer.contact')}</Titulo>
      <Info>C.C. Mata de Coco</Info>
      <Info>PB Local 13 -14 -15</Info>
      <a href="tel:+584123793854">
        <Info to={true}>+58 4123793854</Info>
      </a>
      <a href="mailto:contacto@palospanas.com">
        <Info to={true}>contacto@palospanas.com</Info>
      </a>
    </Container>
  );
};

export default Contacto;
