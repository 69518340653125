import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../../../utils/constants';
import { Productora } from '../landing-page/conocenos/components';

export const ContenidoDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  margin: 0 4vw 4vw 4vw;
  padding: 2vw 5vw 4vw 5vw;
  position: relative;

  @media ${device.mobile} {
    margin: 0 3em 3em 3em;
    align-items: center;
    padding: 2em 1em;
    margin: 0;
    margin-bottom: 1em;
  }
`;

const InfoText = styled.label`
  font-family: FuturaMedium;
  font-size: 1.2vw;
  color: white;
  font-style: ${({ fontStyle }) => fontStyle ?? 'italic'};
  text-align: justify;

  @media ${device.mobile} {
    font-size: 1.1em;
  }
`;

const LabelText = styled.label`
  font-family: FuturaMedium;
  font-size: 1.2vw;
  color: white;
  font-style: ${({ fontStyle }) => fontStyle ?? 'italic'};
  text-align: justify;
  margin-top: 2vw;

  @media ${device.mobile} {
    margin-top: 20px;
    font-size: 1.1em;
  }
`;

const Foto = styled.img`
  background-color: transparent;
  width: 28vw;
  margin-right: 10px;
  margin-bottom: 5px;

  @media ${device.mobile} {
    margin-bottom: 0;
    width: 200px;
  }
`;

const LaLiebreComponent = ({ select, info }) => {
  useEffect(() => window.scrollTo(0, 0), []);

  const mobile = useMediaQuery({ query: device.mobile });

  const lang = window.localStorage.getItem('i18nextLngPLP');

  const letra = (
    <Productora
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="300"
      size={mobile ? '25vw' : '4.6vw'}
      sizeMobile="8vw"
      style={{
        textAlign: 'center',
        lineHeight: mobile ? '1em' : '3.5vw',
        padding: mobile ? '.5em 0em' : '0',
        marginBottom: '.4em',
      }}
    >
      {info.titleTop}
      <br />
      {info.titleBottom}
    </Productora>
  );

  const footo = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Foto
        color="black"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="300"
        src={info.foto ?? 'img/foto.png'}
      />
      {letra}
    </div>
  );

  return (
    <ContenidoDiv
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="300"
    >
      {footo}
      <InfoText
        color="black"
        fontStyle={info.id === 3 ? 'normal' : null}
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="500"
      >
        {info.textoES[0]}
        <br />
        {lang === 'en'
          ? info.textoEN.map(
              (el, i) =>
                i !== 0 && (
                  <LabelText
                    fontStyle={info.id === 3 ? 'normal' : null}
                    key={i}
                  >
                    {el}
                  </LabelText>
                )
            )
          : info.textoES.map(
              (el, i) =>
                i !== 0 && (
                  <LabelText
                    fontStyle={info.id === 3 ? 'normal' : null}
                    key={i}
                  >
                    {el}
                  </LabelText>
                )
            )}
      </InfoText>
      <div
        color="black"
        style={{
          fontFamily: 'FuturaRegular',
          marginTop: '3em',
          width: '100%',
          color: 'white',
          display: 'flex',
          flexDirection: mobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <label
          style={{
            fontFamily: 'FuturaMedium',
            fontSize: mobile ? '1.1em' : '1.1vw',
          }}
        >
          {lang === 'en' ? info.footerLetfEN : info.footerLetfES}
        </label>
        <div
          style={{
            width: mobile ? '80%' : '30%',
            backgroundColor: 'white',
            height: '2px',
            margin: mobile ? '1em 0' : '0',
          }}
        ></div>
        <label
          style={{
            fontSize: mobile ? '1.1em' : '1.1vw',
            fontFamily: 'FuturaMedium',
            fontStyle: 'italic',
          }}
        >
          {lang === 'en' ? info.footerRigthEN : info.footerRigthES}
        </label>
      </div>
    </ContenidoDiv>
  );
};

export default LaLiebreComponent;
