import React from 'react';
import { useSelector } from 'react-redux';
import BannerServicios from '../../servicios/banner.servicios';
import CastingServicios from '../../servicios/casting.servicios';
import DirectorServicios from '../../servicios/director.servicios';
import FooterServicios from '../../servicios/footer.servicios';
import HeaderServicios from '../../servicios/header.servicios';
import LocationServicios from '../../servicios/location.servicios';
import LogisticasServicios from '../../servicios/logisticas.servicios';
import NosotrosServicios from '../../servicios/nosotros.servicios';
import LayoutMenu from '../home/layoutHome';

const ProduccionNew = () => {
  const landingStore = useSelector((state) => state.landing);

  return (
    <LayoutMenu menuBlack={landingStore.openMenu} isLogoTop={false}>
      <HeaderServicios />
      <BannerServicios />
      <LocationServicios />
      <LogisticasServicios />
      <CastingServicios />
      <NosotrosServicios />
      <DirectorServicios />
      <FooterServicios />
    </LayoutMenu>
  );
};

export default ProduccionNew;
