import { SET_DATA_AUTH, SET_LOGOUT } from "../types";
import * as API from "../../services";

export const loginAction = async ({ dispatch, history, user, pwd }) => {
  try {
    const res = await API.loginService(user, pwd);
    if (res.data.statusCode === 200) {
      const payload = res.data.data;
      localStorage.setItem("PLPtoken", payload.accessToken);
      dispatch({
        type: SET_DATA_AUTH,
        payload,
      });
      history.push("/admin/clientes");
      return;
    }

    alert(res.data.data.message);
  } catch (e) {
    alert("Error al intentar la conexion. por favor revise su conexion");
  }
};

export const logoutAction = async ({ dispatch, history }) => {
  localStorage.removeItem("PLPtoken");
  localStorage.removeItem("persist:PLP");
  dispatch({
    type: SET_LOGOUT,
  });
  history.push("/login");
};
