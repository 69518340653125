import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginAction } from "../../../../../store/actions";
import { Button, Form, Row, Col, FormGroup, Input, Label } from "reactstrap";
import { device } from "../../../../../utils/constants";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  h2 {
    color: grey;
  }
`;
const Body = styled.div`
  width: 25vw;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 2px solid #d3d3d3;
  padding: 2vw;

  Label {
    color: white;
    font-family: "FuturaLigth";
  }

  @media ${device.mobile} {
    width: 90vw;
  }
`;

const Titulo = styled.label`
  font-family: "FuturaBold";
  font-size: 4vw;
  color: white;
  margin: 3vw;
`;

const FormLogin = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const userRef = useRef();
  const [user, setUser] = useState();
  const [pwd, setPwd] = useState();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user && pwd) return await loginAction({ dispatch, history, user, pwd });
    alert("Faltan datos en el formulario");
  };

  return (
    <Container>
      <Titulo>Pa' Los Panas Producciones</Titulo>
      <h2>Inicio de sesión</h2>
      <Body>
        <Form className="form" onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={12}>
              <FormGroup>
                <Label for="exampleEmail">Usuario</Label>
                <Input
                  type="email"
                  name="email"
                  ref={userRef}
                  id="exampleEmail"
                  placeholder="Usuario"
                  onChange={(e) => setUser(e.target.value)}
                  autoComplete="off"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <FormGroup>
                <Label for="examplePassword">Contraseña</Label>
                <Input
                  type="password"
                  name="password"
                  id="examplePassword"
                  placeholder="Contraseña"
                  onChange={(e) => setPwd(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Button>Entrar</Button>
        </Form>
      </Body>
    </Container>
  );
};

Form.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default FormLogin;
