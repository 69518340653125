import { GET_ALL_CODIGOS, SET_CODIGOS_ACTIVO } from "../types";
import {
  activoCodigosService,
  buscarCodigosService,
  deleteCodigoPeliculaService,
  getAllCodigosService,
  getOneCodigosService,
  saveCodigoPeliculaService,
  saveCodigosService,
} from "../../services";
import { saveArchivosAction } from "./archivos.action";
import {
  getAllClientesAction,
  setActivoClientesAction,
} from "./clientes.action";

export const getAllCodigosAction = async (dispatch, page) => {
  const res = await getAllCodigosService(page);
  dispatch({
    type: GET_ALL_CODIGOS,
    payload: res.data,
  });
  return res;
};

export const saveCodigosAction = async (dispatch, id, data, file) => {
  try {
    const res = await saveCodigosService(id, data);
    if (res.status < 300 && file) {
      const resF = await saveArchivosAction(file, {
        idPelicula: res.data.data.id,
      });
      if (res.status > 300) alert(resF.data.message);
    }

    alert(res.data.message);
    await getAllCodigosAction(dispatch, null);
    return res.status < 300;
  } catch (e) {
    if (e.response.data) {
      alert(e.response.data.message);
    }
    console.log(e);
  }
};

export const saveCodigoPeliculaAction = async (
  dispatch,
  idCodigo,
  idPelicula,
) => {
  try {
    const res = await saveCodigoPeliculaService(idCodigo, idPelicula);
    await getAllCodigosAction(dispatch, null);
    alert(res.data.message);
    return res.status < 300;
  } catch (e) {
    if (e.response.data) {
      alert(e.response.data.message);
    }
    console.log(e);
  }
};

export const getOneCodigosAction = async (dispatch, { codigo, id }) => {
  const res = await getOneCodigosService({ codigo, id });
  setActivoCodigosAction(dispatch, res.data.data);
  return res;
};

export const activoAction = async (dispatch, cliente, id, status) => {
  const res = await activoCodigosService(id, cliente, status ? 0 : 1);
  setActivoClientesAction({ dispatch, data: res.data.data });
  await getAllClientesAction({ dispatch });
  return res.status === 200;
};

export const buscarCodigosAction = async (dispatch, code) => {
  try {
    const res = await buscarCodigosService(code);
    setActivoCodigosAction(dispatch, res.data.data);
    return res.status === 200;
  } catch (e) {
    return e.response.data;
  }
};

export const setActivoCodigosAction = async (dispatch, data) => {
  dispatch({
    type: SET_CODIGOS_ACTIVO,
    payload: data,
  });
};

export const deleteCodigoPeliculaAction = async (
  dispatch,
  idCodigo,
  idPelicula,
) => {
  try {
    const res = await deleteCodigoPeliculaService(idCodigo, idPelicula);
    await getAllCodigosAction(dispatch, null);
    alert(res.data.message);
    return res.status < 300;
  } catch (e) {
    if (e.response.data) {
      alert(e.response.data.message);
    }
    console.log(e);
  }
};
